import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { HelpBtn, BlueBtn, OutlineBtn } from '../btns'
import { MealTemplate } from './MealTemplate';
import moment from 'moment';
import { AddTemplate } from './AddTemplate';


import { useQuery, useMutation } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';

import * as queries from '../../api/queries.js';

const AllTemplates = () => {
    const [selectedPlanID, setSelectedPlan] = useState(null);
    const { loading, error, data } = useQuery(queries.DB_TEMPLATES);

    if(loading) {
      return "Loading";
    }

    const plans = data.allTemplatePlans;
    let allPlans = plans.map((plan, index) => (
      <div key={index} className={`mealplan ${plan.id === selectedPlanID ? 'active' : ''}`}>
        <OutlineBtn
          onClick={() => setSelectedPlan(plan.id)}
          label={plan.name}
          type='submit'
          className='flex-1 small full-width'
        />
      </div>
    ))

    const selectedPlan = plans.find(plan => plan.id === selectedPlanID);
    return (
      <div className="mealplans">
        <div className="plans">
            <h2>Templates</h2>
          <div className="plans-inner">
            { allPlans }
            <BlueBtn
              onClick={() => setSelectedPlan(null)}
              label='Add Template'
              type='submit'
              className='flex-1 small full-width'
            />
          </div>
        </div>
        {selectedPlan ? (
          <MealTemplate plan={selectedPlan} />
        ) : (
          <AddTemplate />
        )}
      </div>
    )
}

export {AllTemplates};
