import React from 'react'
import { Redirect } from 'react-router-dom'
import { useQuery } from '@apollo/react-hooks'

import { useApi } from '../contexts/api'
import * as queries from '../api/queries.js'
import { Loading } from './'

export const CustomRedirect = ({ newPath, withId }) => {
    const api = useApi()

    const { loading, error, data } = useQuery(queries.PROFILE_ID)

    if (withId && (loading || data == undefined)) {
      return <Loading/>
    } else {
      return <Redirect to={`${newPath}` + (withId ? `/${data.me.profile.id}` : '')} />
    }
}
