import React  from 'react'
import { useQuery } from '@apollo/react-hooks'
import { AllMealPlans } from '../components/plan/AllMealPlans'
import * as queries from '../api/queries.js'
import { Loading } from '../components/loading'

const MealPlans = () => {

  const { loading, error, data } = useQuery(queries.DB_USER_DASHBOARD)

  if(loading) {
    return <Loading />
  }

  return (
    <div className="Main mealtemplate">
      <AllMealPlans data={data} />
    </div>
  )
}

export {MealPlans}
