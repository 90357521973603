import React, { useState, useEffect, useContext } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import axios from 'axios'
import queryString from 'query-string'

import { useApi } from '../contexts/api'
import { useForm } from '../hooks/form'
import logo from '../../images/logo.png'
import { BlueBtn, OutlineBtn } from '../components/btns'
import { LtInput } from '../components/forms/input'
import { Error } from '../components/forms/errors'
import { AFTER_LOGIN_URL, RESET_URL, CONFIRM_RESET_URL } from '../api/constants'
import { NavContext } from '../contexts/nav-context'

const ResetPassword = ({ token, qstring }) => {
  const api = useApi()
  const confirmResetPassword = (username, password) => {
    let promise
    if (!promise) {
      promise = api.request(CONFIRM_RESET_URL, { body: { username, password, token }})
        .then(() =>  api.login({ username, password }))
    }
    return promise
  }

  const stateSchema = {
    username: {
      value: qstring.username,
      error: '',
      validations: {
        isRequired: { message: 'Username is Required'},
      }
    },
    password: {
      value: '',
      error: '',
      validations: {
        isRequired: { message: 'Password is Required'},
      }
    }
  }

  const {
    isSuccess,
    isPending,
    isRejected,
    isDisabled,
    handleSubmit,
    handleOnChange,
    formState,
    error,
  } = useForm(stateSchema, ({ username, password }) => confirmResetPassword(username, password))

  return (
    <div>
      {isSuccess && !isRejected && !error ? (
        <div className="info">
          Your password has been reset. <Link to="/login">Click Here</Link> to sign in.
        </div>
      ) : (
        <form  className="login-form" onSubmit={handleSubmit}>
          <h1>Reset Password</h1>
          { (isRejected && error) &&  <Error message={error.message} /> }
          <LtInput
            name="username"
            label="Username"
            {...formState.username}
          />
          <LtInput
            name="password"
            label="New Password"
            onChange={handleOnChange}
            {...formState.password}
            type="password"
          />
          <div className="lt-navigator">
            <BlueBtn
              label='Reset and Log In'
              type='submit'
              className='flex-1'
              isRunning={isPending}
              disabled={isDisabled}
            />
          </div>
        </form>
      )}
      <div className="bottom-link">
        <Link className="bottom-link" to="/login">Sign in</Link>
      </div>
    </div>
  )

}

const ForgotPassword = () => {

  const stateSchema = {
    email: {
      value: '',
      error: '',
      validations: {
        isRequired: { message: 'Email is Required'},
        isEmail: { message: 'Please enter a valid email'},
      }
    }
  }

  const resetPassword = email => {
    let promise
    if (!promise) {
      promise = axios.post(RESET_URL, { email })
    }
    return promise
  }

  const {
    isSuccess,
    isPending,
    isRejected,
    isDisabled,
    handleSubmit,
    handleOnChange,
    formState,
    error,
  } = useForm(stateSchema, ({ email }) => resetPassword(email))

  return (
    <>
      {isSuccess && !isRejected && !error ? (
        <div className="info">
          An email has been sent to {formState.email.value}
        </div>
      ) : (
        <form  className="login-form" onSubmit={handleSubmit}>
          <h1>Forgot Password</h1>
          { (isRejected && error) &&  <Error message={error.message} /> }
          <LtInput
            name="email"
            label="Email"
            onChange={handleOnChange}
            {...formState.email}
          />
          <div className="lt-navigator">
            <BlueBtn
              label='Send Email'
              type='submit'
              className='flex-1'
              isRunning={isPending}
              disabled={isDisabled}
            />
          </div>
        </form>
      )}
    </>
  )
}

const LoginContainer = ({ children }) => {
  return (
    <div className="login-container">
      <div className="login-sidebar">
        <img src={logo} alt="logo" />
        <div className="login-form-wrapper">
          {children}
        </div>
      </div>
    </div>
  )
}

const LoginForm = ({ api }) => {

  const [showing, setShowing] = useState(false)
  const { token, ...qstring } = queryString.parse(useLocation().search)

  const stateSchema = {
    username: {
      value: '',
      error: '',
      validations: {
        isRequired: { message: 'Username is Required'},
      }
    },
    password: {
      value: '',
      error: '',
      validations: {
        isRequired: { message: 'Password is Required'},
      }
    }
  }

  const {
    isSuccess,
    isPending,
    isRejected,
    isDisabled,
    handleSubmit,
    handleOnChange,
    formState,
    error,
  } = useForm(stateSchema, ({username, password}) => api.login({username, password}))

  return (
    <div>
      {token ? (
        <ResetPassword token={token} qstring={qstring} />
      ) : (
        <div>
          {showing ? <ForgotPassword /> : (
            <form  className="login-form" onSubmit={handleSubmit}>
              <h1>Sign in</h1>
              { (isRejected && error) &&  <Error message={error.message} /> }
              <LtInput
                name="username"
                label="Username"
                onChange={handleOnChange}
                {...formState.username}
              />
              <LtInput
                name="password"
                label="Password"
                onChange={handleOnChange}
                {...formState.password}
                type="password"
              />
              <div className="lt-navigator">
                <BlueBtn
                  label='Sign-in'
                  type='submit'
                  className='flex-1'
                  isRunning={isPending}
                  disabled={isDisabled}
                />
              </div>
            </form>
          )}
          <div className="bottom-link" onClick={() => setShowing(!showing)}>
            {showing ? 'Sign In' : 'Forgot Password?'}
          </div>
        </div>
      )}
    </div>
  )
}


export const Login = props => {
  const api = useApi()
  const token = api.getToken()
  const history = useHistory()
  const { setIsNav } = useContext(NavContext)

  useEffect(() => {
    setIsNav(true)
  }, [])

  useEffect(() => {
    if (token) {
      history.push(AFTER_LOGIN_URL)
    }
  }, [token])

  return (
    <LoginContainer>
      <LoginForm api={api} {...props} />
    </LoginContainer>
  )
}


const RegisterForm = ({ onSubmit }) => {
  const stateSchema = {
    username: {
      value: '',
      error: '',
      validations: {
        isRequired: { message: 'Username is Required'},
      }
    },
    email: {
      value: '',
      error: '',
      validations: {
        isRequired: { message: 'Email is Required'},
        isEmail: { message: 'Enter a valid email'},
      }
    },
    firstName: {
      value: '',
      error: '',
      validations: {
        isRequired: { message: 'Add Your First Name'},
      }
    },
    lastName: {
      value: '',
      error: '',
      validations: {
        isRequired: { message: 'Add Your Last Name'},
      }
    },
    password: {
      value: '',
      error: '',
      validations: {
        isRequired: { message: 'Password is Required'},
      }
    },
    confirmPassword: {
      value: '',
      error: '',
      validations: {
        isRequired: { message: 'Confirm your password'},
      }
    }
  }
  const {
    isSuccess,
    isPending,
    isRejected,
    isDisabled,
    handleSubmit,
    handleOnChange,
    formState,
    error,
  } = useForm(stateSchema, onSubmit)

  const history = useHistory()



  return (
    <form onSubmit={handleSubmit}>
      <h1>Sign up</h1>
      { (isRejected && error) &&  <Error message={error.message} /> }
      <LtInput
        name="username"
        label="Username"
        onChange={handleOnChange}
        {...formState.username}
      />
      <LtInput
        name="email"
        label="Email Address"
        onChange={handleOnChange}
        {...formState.email}
      />
      <LtInput
        name="firstName"
        label="First Name"
        onChange={handleOnChange}
        {...formState.firstName}
      />
      <LtInput
        name="lastName"
        label="Last Name"
        onChange={handleOnChange}
        {...formState.lastName}
      />
      <LtInput
        name="password"
        label="Password"
        onChange={handleOnChange}
        {...formState.password}
        type="password"
      />
      <div>
        <OutlineBtn
          label='Sign-in'
          onClick={() => history.push('/login')}
        />
        <BlueBtn label='Create Your Account' type='submit' isRunning={false} />
      </div>
      { isRejected ? (<div>{ error ? error.message : null }</div>) : null }
    </form>
  )
}


export const SignUp = props => {
  const api = useApi()
  const token = api.getToken()
  const history = useHistory()

  useEffect(() => {
    if (token) {
      history.push(AFTER_LOGIN_URL)
    }
  }, [token])

  return (
    <LoginContainer>
      <RegisterForm {...props} onSubmit={({username, email, firstName, lastName, password}) => {
        api.register({username, email, firstName, lastName, password})
      }}
      />
    </LoginContainer>
  )
}
