import React, { useState } from 'react'
import moment from 'moment'
import Recipe from './Recipe.js'
import AllMeals from './AllMeals.js'
import { CloseBtn } from '../btns'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'

import { DAY } from '../../api/constants'


const Popup = (props) => {
  return (
    <div className='popup'>
      <div className='popup_inner'>
        <CloseBtn
          onClick={() => props.closePopup()}
          type='submit'
          className='small'
        />
        <h1> {props.text} </h1>
        <AllMeals {...props} />
      </div>
    </div>
  )
}


const RecipeHolder = (props) => {

  const [showPopup, setShowPopup] = useState(false)

  const togglePopup = () => {
    setShowPopup(!showPopup)
  }

  return (
    <>
      <button className="btn add-more" onClick={() => togglePopup()}>
        <FontAwesomeIcon className="small" icon={faEdit} />
      </button>
      {showPopup ?
        <Popup
          text={`Select ${props.type} for ${moment(props.date).format('MMMM D')}`}
          closePopup={() => togglePopup()}
          {...props}
        />
        : null
      }
    </>
  )
}


const Meal = (props) => {
  const mealRecipesList = props.mealRecipes.map(({ id, name, overnightPrep }, index ) => (
    <div key={index}>
      <Recipe id={id} name={name} />
      {overnightPrep ? (
        <FontAwesomeIcon
          onClick={() => {
            const offset = moment(props.date).startOf('day').diff(moment().startOf('day'), 'days')
            props.setStartDate(moment().add(offset, 'd'))
            props.setEndDate(moment().add(offset, 'd'))
            props.setDayOffset(offset)
            props.setCalendarView(DAY)
          }}
          className="small exclamation clickable"
          icon={faExclamationTriangle}
        />
      ) : ''}
    </div>
  ))

  const fasting = mealRecipesList == 0 && props.name != 'Leftovers' ? (
    <div className="recipe">Fasting</div>
  ) : null

  return (
    <div className="meal">
      <div className="add-recipes">
        {props.type} {props.leftovers && !fasting ? ' - Leftovers' : null}
        {props.editMode ? <RecipeHolder {...props} /> : ''}
      </div>
      <div className="recipes">
        {fasting ? fasting : (props.leftovers ? props.name : mealRecipesList)}
      </div>
    </div>
  )
}

export default Meal
