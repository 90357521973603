import React, { useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import { useCookies } from 'react-cookie'

import { useApi } from '../contexts/api'
import { Loading } from '../components'
import { COOKIE_NAME } from '../api/constants'


export const Logout = props => {
    const api = useApi()
    const token = api.getToken()

    useEffect(() => {
      if (token) {
        api.logout()
      }
    }, [token])

    return token ? <Loading/> : <Redirect to='/login'/>
}
