import React, { useState } from 'react'
import { useApi } from '../contexts/api'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'

import { Loading } from '../components/loading'
import { LtSelect, LtInput, TextArea } from  '../components/forms'
import { OutlineBtn, BlueBtn } from '../components/btns'
import { useForm } from '../hooks/form'
import { validate } from '../utils/validators'

import * as queries from '../api/queries'
import { PORTION_UNITS } from '../api/constants'


const IngField = (props) => {
  return (
    <div className="section">
      <LtInput
        name="portionSize"
        label="Portion Size"
        placeholder="1 1/2"
        {...props.portionSize}
        onChange={event => props.changeField(props.index, props.oldList, props.setter, event)}
      />
      <LtSelect
        name="portionUnit"
        label="Portion Unit"
        options={PORTION_UNITS}
        {...props.portionUnit}
        onChange={event => props.changeField(props.index, props.oldList, props.setter, event)}
      />
      <LtSelect
        name="ingredient"
        label="Ingredient"
        options={props.selectIngredients}
        {...props.ingredient}
        onChange={event => props.changeField(props.index, props.oldList, props.setter, event)}
      />
      <LtInput
        name="modifier"
        label="Modifier"
        placeholder="chopped, cooked"
        {...props.modifier}
        onChange={event => props.changeField(props.index, props.oldList, props.setter, event)}
      />
      <div className="delete">
        <FontAwesomeIcon onClick={() => props.removeIng(props.index)} icon={faTrashAlt} />
      </div>
    </div>
  )
}

const InstructionField = (props) => {
  return (
    <div className="section">
      <div className="step">{props.index + 1}</div>
      <TextArea
        name="instruction"
        placeholder="Add text here..."
        {...props.instruction}
        onChange={event => props.changeField(props.index, props.oldList, props.setter, event)}
      />
      <div className="delete">
        <FontAwesomeIcon onClick={() => props.removeInstruction(props.index)} icon={faTrashAlt} />
      </div>
    </div>
  )
}

const RecipeSubmit = () => {
  const api = useApi()

  const [createRecipe, createRecipeData] = api.useMutation(queries.CREATE_RECIPE)

  const ingSchema = [{
    portionSize: {
      value: '',
      error: '',
      validations: {
        isRequired: { message: 'Required' },
      }
    },
    portionUnit: {
      value: '',
      error: '',
      validations: {
        isRequired: { message: 'Select a Unit' },
      }
    },
    ingredient: {
      value: '',
      error: '',
      validations: {
        isRequired: { message: 'Select an Ingredient' },
      }
    },
    modifier: {
      value: '',
      error: '',
    },
  }]

  const instructionSchema = [{
    instruction: {
      value: '',
      error: '',
      validations: {
        isRequired: { message: 'Enter instructions for this step' },
      }
    },
    duration: {
      value: 0,
      error: '',
    },
  }]

  const [ingList, setIngList] = useState(ingSchema)
  const [instructionList, setInstructionList] = useState(instructionSchema)

  const stateSchema = {
    recipeName: {
      value: '',
      error: '',
      validations: {
        isRequired: { message: 'Enter a Recipe Name' },
      }
    },
    description: {
      value: '',
      error: '',
    },
    prepTime: {
      value: '',
      error: '',
      validations: {
        isRequired: { message: 'Required' },
        notNegative: { message: 'Can\'t be negative' }
      }
    },
    cookTime: {
      value: '',
      error: '',
      validations: {
        isRequired: { message: 'Required' },
        notNegative: { message: 'Can\'t be negative' }
      }
    },
    servings: {
      value: '',
      error: '',
      validations: {
        isRequired: { message: 'Required' },
        isPositive: { message: 'Must be greater than 0' }
      }
    },
    servingSize: {
      value: '',
      error: '',
      validations: {
        isRequired: { message: 'Required' },
        isPositive: { message: 'Must be greater than 0' }
      }
    },
    sizeUnit: {
      value: '',
      error: '',
      validations: {
        isRequired: { message: 'Required' },
      }
    },
  }

  const {
    isSuccess,
    isPending,
    isRejected,
    isDisabled,
    handleSubmit,
    handleOnChange,
    formState,
  } = useForm(stateSchema, () => createRecipe({
    variables: {
      recipeData: {
        name: formState.recipeName.value,
        description: formState.description.value,
        prepTime: parseFloat(formState.prepTime.value) * 60,
        cookTime: parseFloat(formState.cookTime.value) * 60,
        season: [],
        servings: formState.servings.value,
        servingSize: formState.servingSize.value ? formState.servingSize.value : null,
        sizeUnit: formState.sizeUnit.value.value,
        recipeIngredients: ingList.map((ing) => {
          return {
            portionSize: ing.portionSize.value,
            portionUnit: ing.portionUnit.value.value,
            ingredientId: ing.ingredient.value.value,
            modifier: ing.modifier.value ? ing.modifier.value : ''
          }
        }),
        tags: [],
        instructions: instructionList.map((instruction, index) => {
          return {
            step: index + 1,
            description: instruction.instruction.value,
            duration: instruction.duration.value
          }
        })
      }
    }
  }))

  const dbData = api.useQuery(queries.DB_RECIPE_DATA)

  if (dbData.loading) {
    return <Loading />
  }

  if (isSuccess) {
    return <div className="create-recipe">
      Your recipe has been submitted for review! You can&nbsp;
      <Link to="/recipe-submit" onClick={() => window.location.reload()}>
        submit another recipe
      </Link> or&nbsp;
      <Link to="/dashboard">
        go back to my home page.
      </Link>
    </div>
  }

  const selectIngredients = dbData.data.allIngredients.map((value) => (
    { label: value.name, value: value.id }
  ))


  const changeField = (index, oldList, setter, event) => {
    let newList = [...oldList]

    const name = event.target.name
    const value = event.target.value
    const validations = newList[index][name].validations ? newList[index][name].validations : null

    let error = ""

    if (validations !== null && typeof validations === 'object') {
      for (const [key, properties] of Object.entries(validations)) {

        let validationValue = value
        if(typeof value === "object") {
          validationValue = value.value
        }

        const result = validate[key](validationValue, properties.options)
        if(!result) {
          error = properties.message
          break
        }
      }
    }

    newList[index] = {...newList[index], [name]: {value, error, validations}}

    setter(newList)
  }

  const addIng = () => {
    setIngList(ingList.concat(ingSchema))
  }

  const addInstruction = () => {
    setInstructionList(instructionList.concat(instructionSchema))
  }

  const removeIng = (index) => {
    const newList = [...ingList]
    newList.splice(index, 1)
    setIngList(newList)
  }

  const removeInstruction = (index) => {
    const newList = [...instructionList]
    newList.splice(index, 1)
    setInstructionList(newList)
  }

  const isDirty = () => {
    if (ingList.some(item => !item.portionSize.value ||
        !item.portionUnit.value || !item.ingredient.value)) {
      return true
    }

    if (instructionList.some(item => !item.instruction.value)) {
      return true
    }

    if (!formState.recipeName.value || !formState.prepTime.value ||
        !formState.cookTime.value || !formState.servings.value) {
      return true
    }

    return false
  }

  return (
    <div>
      <form className="create-recipe" autoComplete="off" onSubmit={handleSubmit}>
        {isRejected ? <div className="alert alert-danger">There was an error when submitting your recipe. Check your recipe and try again.</div> : ''}
        <h1>Submit a Recipe</h1>
        <div>
          <LtInput
            name="recipeName"
            label="Recipe Name"
            {...formState.recipeName}
            onChange={handleOnChange}
          />
          <TextArea
            name="description"
            label="Description"
            {...formState.description}
            onChange={handleOnChange}
          />
        </div>
        <div className="section">
          <LtInput
            name="prepTime"
            label="Preparation Time (Minutes)"
            placeholder="5"
            min={0}
            type="number"
            {...formState.prepTime}
            onChange={handleOnChange}
          />
          <LtInput
            name="cookTime"
            label="Cook Time (Minutes)"
            placeholder="10"
            min={0}
            type="number"
            {...formState.cookTime}
            onChange={handleOnChange}
          />
          <LtInput
            name="servings"
            label="Servings"
            min={1}
            type="number"
            {...formState.servings}
            onChange={handleOnChange}
          />
          <LtInput
            name="servingSize"
            label="Serving Size"
            min={1}
            type="number"
            {...formState.servingSize}
            onChange={handleOnChange}
          />
          <LtSelect
            name="sizeUnit"
            label="Size Unit"
            options={PORTION_UNITS}
            {...formState.sizeUnit}
            onChange={handleOnChange}
          />
        </div>
        <div className="wrapper">
          <h2>Ingredients</h2>
          {ingList.map((item, index) => (
            <IngField
              key={index}
              index={index}
              selectIngredients={selectIngredients}
              changeField={changeField}
              removeIng={removeIng}
              oldList={ingList}
              setter={setIngList}
              {...item}
            />
          ))}
          <OutlineBtn label="Add Ingredient" className="small" onClick={() => addIng()} />
        </div>
        <div className="wrapper">
          <h2>Instructions</h2>
          {instructionList.map((item, index) => (
            <InstructionField
              key={index}
              index={index}
              changeField={changeField}
              removeInstruction={removeInstruction}
              oldList={instructionList}
              setter={setInstructionList}
              {...item}
            />
          ))}
          <OutlineBtn label="Add Step" className="small" onClick={() => addInstruction()} />
        </div>
        <BlueBtn disabled={isDisabled || isDirty()} className="submit" label="Submit" type="submit" />
      </form>
    </div>
  )
}

export { RecipeSubmit }
