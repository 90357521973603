import React, { useState } from 'react'
import { useApi } from '../contexts/api'
import { useParams } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'
import { Link } from 'react-router-dom'

import { LtDate } from  '../components/forms'
import { BlueBtn } from '../components/btns'
import { DAY, WEEK, TWO_WEEK, MONTH, DATE_FORMAT } from '../api/constants.js'
import ShoppingList from '../components/details/ShoppingList'

import * as queries from '../api/queries.js'


const DayNav = ({ setDayOffset, dayOffset, selectedDate, timeInterval, ...props }) => {
  var displayDate
  if (props.time == MONTH) {
    displayDate = moment(selectedDate).format('MMMM YYYY')
  } else if (props.time == DAY) {
    if (dayOffset == 0) {
      displayDate = 'Today'
    } else if (dayOffset == 1) {
      displayDate = 'Tomorrow'
    } else if (dayOffset == -1) {
      displayDate = 'Yesterday'
    } else {
      displayDate = moment(selectedDate).format('MMMM D')
    }
  } else {
    displayDate = `${moment(props.startDate).format('MMM D')} - ${moment(props.endDate).format('MMM D')}`
  }
  return (
    <div className='header-date-step'>
      <button className='back' onClick={() => {
        const newDate = moment(selectedDate).startOf('day').add(-timeInterval, props.time)
        setDayOffset(parseInt(newDate.diff(moment().startOf('day'), 'days')))
        props.setStartDate(moment(selectedDate).add(-timeInterval, props.time).startOf(props.time))
        props.setEndDate(moment(selectedDate).add(-timeInterval + props.added, props.time).endOf(props.time))
      }}><FontAwesomeIcon icon={faChevronLeft}/></button>
      <button className='back' onClick={() => {
        setDayOffset(0)
        props.setStartDate(moment().startOf(props.time))
        props.setEndDate(moment().add(props.added, props.time).endOf(props.time))
      }}>Today</button>
      <button className='forward' onClick={() => {
        const newDate = moment(selectedDate).startOf('day').add(timeInterval, props.time)
        setDayOffset(parseInt(newDate.diff(moment().startOf('day'), 'days')))
        props.setStartDate(moment(selectedDate).add(timeInterval, props.time).startOf(props.time))
        props.setEndDate(moment(selectedDate).add(timeInterval + props.added, props.time).endOf(props.time))
      }}><FontAwesomeIcon icon={faChevronRight}/></button>
      <h2>{displayDate}</h2>
    </div>
  )
}

const DateSelectors = ({ data, loading, ...props}) => {
  return (
    <form className="create-mealplan" autoComplete="off">
      <LtDate
        name="startDate"
        label="Start Date"
        value={props.startDate}
        onChange={(e) => props.setStartDate(moment(e.target.value))}
      />
      <LtDate
        name="endDate"
        label="End Date"
        value={props.endDate}
        onChange={(e) => props.setEndDate(moment(e.target.value))}
      />
      <BlueBtn
        onClick={async () => {
          props.setShoppingListFetching(true)
          if (props.shoppingListObj) {
            await props.api.getShoppingList(props.shoppingListObj)
          } else {
            await props.api.getShoppingList(JSON.parse(data.shoppingList.items))
          }
          props.setShoppingListFetching(false)
        }}
        label='Print Shopping List'
        isRunning={loading || props.shoppingListFetching}
        className='flex-1 small'
        disabled={loading || props.shoppingListFetching}
      />
    </form>
  )
}

const CalendarPicker = ({ selectedDate, calendarView, ...props }) => {
  return (
    <div className='header-calendar-picker'>
      <button
        onClick={() => {
          props.setCalendarView(DAY)
          props.setTimeInterval(1)
          props.setStartDate(selectedDate)
          props.setEndDate(selectedDate)
        }}
        className={calendarView == DAY ? 'header-calendar-picker_active' : 'header-calendar-picker_inactive'}
      >
        Day
      </button>
      <button
        onClick={() => {
          props.setCalendarView(WEEK)
          props.setTimeInterval(1)
          props.setStartDate(moment(selectedDate.startOf('week')))
          props.setEndDate(moment(selectedDate.endOf('week')))
        }}
        className={calendarView == WEEK ? 'header-calendar-picker_active' : 'header-calendar-picker_inactive'}
      >
        Week
      </button>
      <button
        onClick={() => {
          props.setCalendarView(TWO_WEEK)
          props.setTimeInterval(2)
          props.setStartDate(moment(selectedDate).startOf('week'))
          props.setEndDate(moment(selectedDate).add(7, 'd').endOf('week'))
        }}
        className={calendarView == TWO_WEEK ? 'header-calendar-picker_active' : 'header-calendar-picker_inactive'}
      >
        2 Week
      </button>
      <button
        onClick={() => {
          props.setCalendarView(MONTH)
          props.setStartDate(moment(selectedDate).startOf('month'))
          props.setEndDate(moment(selectedDate).endOf('month'))
          props.setTimeInterval(1)
        }}
        className={calendarView == MONTH ? 'header-calendar-picker_active' : 'header-calendar-picker_inactive'}
      >
        Month
      </button>
    </div>
  )
}


const MainDisplay = ({ loading, calendarView, selectedDate, ...props }) => {

  if (props.error) return <p>Error: {props.error.message}</p>

  const api = useApi()


  const [shoppingListFetching, setShoppingListFetching] = useState(false)
  const [editMeal, setEditMeal] = useState(false)

  return (
    <div className='calendar'>
      <DateSelectors
        loading={loading}
        api={api}
        shoppingListFetching={shoppingListFetching}
        setShoppingListFetching={setShoppingListFetching}
        {...props}
      />
      <CalendarPicker
        loading={loading}
        calendarView={calendarView}
        selectedDate={selectedDate}
        editMeal={editMeal}
        setEditMeal={setEditMeal}
        {...props}
      />
      <DayNav
        {...props}
        selectedDate={selectedDate}
        time={calendarView == TWO_WEEK ? 'week' : calendarView}
        added={calendarView == TWO_WEEK ? 1 : 0}
      />
      <ShoppingList
        data={props.data}
        loading={loading}
        startDate={moment(props.startDate).format(DATE_FORMAT)}
        endDate={moment(props.endDate).format(DATE_FORMAT)}
        familyId={props.familyId}
        editMeal={editMeal}
      />
    </div>
  )
}


const ShoppingListScreen = () => {
  const api = useApi()
  const { id } = useParams()

  const [dayOffset, setDayOffset] = useState(0)
  const selectedDate = moment().startOf('day').add(dayOffset, 'd')

  const [calendarView, setCalendarView] = useState(MONTH)
  const [timeInterval, setTimeInterval] = useState(1)
  const [startDate, setStartDate] = useState(moment(selectedDate).startOf(MONTH))
  const [endDate, setEndDate] = useState(moment(selectedDate).endOf(MONTH))

  const { loading, error, data } = api.useQuery(queries.SHOPPING_LIST, {
    variables: {
      familyId: id,
      startDate: moment(startDate).startOf('day').format(DATE_FORMAT),
      endDate: moment(endDate).startOf('day').format(DATE_FORMAT)
    }
  })

  return (
    <div className="Main family">
      <div className="family-header">
        <div className="family-header_inner">
          <h2>Shopping List</h2>
          <div className="link">
            <Link to={'/family/' + id}>Meal Plan Calendar</Link>
          </div>
        </div>
      </div>
      <div className="profile">
        <div className="family-template">
          <MainDisplay
            dayOffset={dayOffset}
            setDayOffset={setDayOffset}
            familyId={id}
            selectedDate={selectedDate}
            calendarView={calendarView}
            setCalendarView={setCalendarView}
            timeInterval={timeInterval}
            setTimeInterval={setTimeInterval}
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            loading={loading}
            data={data}
            error={error}
          />
        </div>
      </div>
    </div>
  )
}

export { ShoppingListScreen }
