import React from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import Popup from "reactjs-popup";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faTimes } from '@fortawesome/free-solid-svg-icons';
import Meal from './Meal.js';
import { RedBtn, OutlineBtn } from '../btns';

import * as queries from '../../api/queries.js'
// Different possible mealtypes
const types = {
  B: 'Breakfast',
  L: 'Lunch',
  D: 'Dinner'
}

const Day = (props) => {

  // Mutation to delete the current Template Day
  const [deleteTemplateDay, { templateDayData }] = useMutation(queries.DELETE_TEMPLATE_DAY, {
    variables: {
      templateDayData: {
        id: props.id,
        dayNumber: props.number,
        templatePlanId: props.plan.id
      }
    },
    refetchQueries: result => [{query: queries.DB_TEMPLATES}],
  });

  // create Meal component for each meal in current Day
  const meals = props.meals.map(({ id, mealType, recipes }, index ) => (
    <Meal
      key={index}
      type={types[mealType]}
      mealRecipes={recipes}
      day={props.number}
      mealPlanId={null}
      mealId={id}
      dayId={props.id}/>
  ));
  const Modal = () => (
    <Popup className="modal--delete" trigger={<span><FontAwesomeIcon icon={faTrashAlt} /></span>} modal>
      {close => (
        <div className="modal">
          <div className="header">
          <h3>Delete Meal Template</h3>
          <FontAwesomeIcon icon={faTimes} onClick={close}/>
          </div>
          <div className="content">
            Are you sure that you want to delete <span className="popup__content--blue">Day {props.number}</span>?
          </div>
          <div className="buttons">
            <OutlineBtn
              onClick={close}
              label='Cancel'
              type='submit'
              className='small'
            />
            <RedBtn
              className='small'
              label='Delete'
              onClick={() => deleteTemplateDay()}
            />
          </div>
        </div>
      )}
    </Popup>
  );
  return (
    <div className="day">
      <div className="number">
        Day {props.number}
        <div className="title__buttons">
          <Modal />
        </div>
      </div>
      <div className="meals">
        {meals}
      </div>
    </div>
  )
}

export default Day;
