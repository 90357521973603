import { gql } from "apollo-boost"

export const PROFILE_ID = gql`
  query GetMe {
    me {
      id
      profile {
        id
        acceptedTerms
        family {
          id
        }
      }
    }
  }
`

export const USER = gql`
  query GetUser($profileId: ID!) {
    me {
      id
      isStaff
    }
    user(profileId: $profileId) {
      id
      firstName
      lastName
      username
      email
      isStaff
      profile {
        id
        phoneNumber
        shoppingFrequency
        shoppingListStyle
        shoppingLocation
        acceptedTerms
        family {
          id
          lastName
          plans {
            id
            planDate
            mealSet {
              id
            }
          }
          address {
            id
            street
            city
            stateProvince
            zipCode
            country
          }
        }
        allergyTestAt
        allergen {
          id
          sensitivity
          updatedAt
          createdAt
          ingredient {
            id
            name
          }
          optionalReplacements {
            id
            name
          }
        }
      }
    }
    potentialDislikes(profileId: $profileId) {
      id
      name
    }
  }
`

export const FAMILY_CALENDAR = gql`
  query GetUserCalendarDetails($familyId: ID!, $startDate: Date, $endDate: Date) {
    mealplansFromDateRange(familyId: $familyId, startDate: $startDate, endDate: $endDate) {
      id
      planDate
      mealSet {
        id
        name
        mealPlan {
          id
        }
        mealType
        leftovers
        recipes {
          id
          name
          overnightPrep
        }
      }
    }
  }
`

export const SHOPPING_LIST = gql`
  query GetShoppingList($familyId: ID!, $startDate: Date!, $endDate: Date!) {
    shoppingList(familyId: $familyId, startDate: $startDate, endDate: $endDate) {
      id
      items
      startDate
      endDate
    }
  }
`

export const CUSTOM_MEAL_PLAN_JSON = gql`
  query GetMealPlanJson($familyId: ID, $startDate: Date!, $endDate: Date!) {
    customMealPlanJson(familyId: $familyId, startDate: $startDate, endDate: $endDate)
  }
`

export const FAMILY = gql`
  query GetFamily($id: ID!) {
    family(id: $id) {
      id
      lastName
      profileSet {
        id
        user {
          id
          firstName
          lastName
          username
        }
      }
    }
    familyAllergens(familyId: $id) {
      id
      patient {
        id
        user {
          id
          firstName
          username
        }
      }
      sensitivity
      ingredient {
        id
        name
      }
      createdAt
      updatedAt
      optionalReplacements {
        id
        name
      }
    }
  }
`

export const RECIPE = gql`
  query GetRecipe($id: ID!) {
    me {
      id
      firstName
      lastName
      profile {
        id
      }
    }
    recipe(id: $id) {
      id
      name
      approved
      rating
      servingSize
      description
      image
      prepTime
      cookTime
      servings
      servingSize
      sizeUnit
      parentRecipe {
        id
        name
      }
      season {
        id
        name
      }
      tags {
        id
        name
      }
      recipeIngredients {
        id
        portionSize
        portionUnit
        ingredient {
          id
          name
        }
        replacement
        modifier
      }
      instructions {
        id
        step
        description
        duration
      }
    }
    reviews(recipeId: $id) {
      profile {
        id
        user {
          id
          firstName
          lastName
        }
      }
      rating
      comment
    }
  }
`

export const DB_USER_DASHBOARD = gql`
  query GetDashboard {
    me {
      id
      firstName
      lastName
      profile {
        id
        family{
          id
          lastName
          plans {
            id
            planDate
            mealSet {
              id
              mealType
              recipes {
                id
                name
                description
                servings
                servingSize
                sizeUnit
                recipeIngredients {
                  id
                  portionSize
                  portionUnit
                  modifier
                  ingredient {
                    id
                    name
                  }
                }
                instructions {
                  id
                  step
                  description
                  duration
                }
                season {
                  id
                  name
                }
              }
            }
          }
        }
      }
    }
  }
`

export const SETUP_PROFILE = gql`
  query GetProfile {
    me {
      id
      firstName
      lastName
      email
      profile {
        id
        phoneNumber
        family {
          id
          size
          address {
            id
            street
            city
            stateProvince
            zipCode
            country
          }
        }
        shoppingFrequency
        shoppingListStyle
        shoppingLocation
        acceptedTerms
      }
    }
  }
`

export const DB_STAFF_DASHBOARD = gql`
  query GetPatients {
    allPatients {
      id
      firstName
      lastName
      username
      email
      isStaff
      lastLogin
      profile {
        id
      }
    }
    allFamilies {
      id
      lastName
      size
      profileSet {
        id
        user {
          id
          username
          firstName
        }
      }
    }
    allRecipes {
      id
      name
      approved
    }
    allMealPlans {
      id
    }
    allTemplatePlans {
      id
    }
  }
`

export const DB_MEAL_PLANS = gql`
  query GetMealPlans($id: ID!) {
    allProfiles(id: $id) {
      id
      name
      family {
        id
        mealPlanSet {
          id
        }
      }
    }
    approvedRecipes {
      name
      id
    }
    allMealPlans {
      planDate
      id
    }
    allTemplatePlans {
      name
      id
    }
  }
`

export const FILTERED_RECIPES = gql`
  query FilteredRecipes($tags: [String!], $foods: [String!], $seasons: [String!]) {
    filteredRecipes(tags: $tags, foods: $foods, seasons: $seasons) {
      id
      name
      image
      rating
    }
  }
`

export const FILTERS = gql`
  query GetFilterTags {
    allRecipeTags {
      id
      name
    }
    allIngredientTags {
      id
      name
    }
    allSeasons {
      id
      name
    }
  }
`

export const DB_TEMPLATES = gql`
  query GetTemplates {
    allTemplatePlans {
      id
      name
      description
    }
    allTemplateDays {
      id
      dayNumber
      templatePlan {
        id
        name
      }
		  mealSet {
        id
        mealType
        recipes {
          name
          id
        }
      }
    }
  }
`

export const DB_RECIPES = gql`
  query GetApprovedRecipes {
    approvedRecipes {
      name
      id
    }
  }
`

export const DB_RECIPE_DATA = gql`
  query GetAllIngredients {
    allIngredients {
      id
      name
    }
  }
`

export const DB_MEAL_RECIPES = gql`
  query GetMealRecipes {
    allMeals {
      id
      recipes {
        id
        name
      }
    }
  }
`


export const NAMED_MEALS = gql`
  query GetNamedMeals($mealDate: Date!, $mealType: String!) {
    namedMeals(mealDate: $mealDate, mealType: $mealType) {
      id
      name
      season {
        id
        name
      }
      recipes {
        id
        name
        rating
        recipeReviews {
          id
          rating
          profile {
            id
            user {
              id
            }
          }
        }
      }
    }
    currentSeasons(givenDate: $mealDate) {
      id
      name
    }
    me {
      id
    }
  }
`

export const EDIT_PROFILE = gql`
  mutation EditProfile($profile: ProfileInput!) {
    editProfile(profileData: $profile) {
      profile {
        id
        phoneNumber
        shoppingFrequency
        shoppingListStyle
        shoppingLocation
        acceptedTerms
        family {
          id
          size
          address {
            id
            street
            city
            stateProvince
            zipCode
            country
          }
        }
        user {
          id
          firstName
          lastName
          email
        }
      }
    }
  }
`

export const UPDATE_MEAL = gql`
  mutation UpdateMeal($mealId: ID!, $copyId: ID) {
    updateMeal(mealId: $mealId, copyId: $copyId) {
      meal {
        id
        mealType
        mealPlan {
          id
        }
        recipes {
          id
          name
        }
      }
    }
  }
`

export const UPDATE_PROFILE_ALLERGENS = gql`
  mutation UpdateProfileAllergens($profileId: ID!) {
    updateProfileAllergens(profileId: $profileId) {
      allergens {
        id
        ingredient {
          id
          name
        }
        sensitivity
      }
    }
  }
`
export const AUTO_GEN_MEAL_PLAN = gql`
  mutation AutoGenMealPlan($familyId: ID!, $numDays: Int!, $startDate: Date!) {
    createMealPlanAuto(familyId: $familyId, numDays: $numDays, startDate: $startDate) {
      mealPlan {
        id
        family {
          id
          lastName
        }
        planDate
        mealSet {
          id
          name
        }
      }
    }
  }
`

export const CREATE_ALLERGEN = gql`
  mutation CreateAllergen($allergenData: AllergenInput!) {
    createAllergen(allergenData: $allergenData) {
      allergen {
        id
        sensitivity
        updatedAt
        createdAt
        ingredient {
          id
          name
        }
        optionalReplacements {
          id
          name
        }
      }
    }
  }
`

export const CREATE_MEAL_PLAN = gql`
  mutation CreateMealPlan($mealPlanData: MealPlanInput!, $familyData: FamilyInput!) {
    createMealPlan(mealPlanData: $mealPlanData, familyData: $familyData) {
      mealPlan {
        id
        planDate
        mealSet {
          id
          mealType
          recipes {
            id
          }
        }
      }
      family {
        id
        lastName
      }
    }
  }
`

export const CREATE_TEMPLATE = gql`
  mutation CreateTemplatePlan($templatePlanData: TemplatePlanInput!) {
    createTemplatePlan(templatePlanData: $templatePlanData) {
      templatePlan {
        id
        name
        description
      }
    }
  }
`

export const CREATE_DAY = gql`
  mutation CreateTemplateDay($templateDayData: TemplateDayInput!) {
    createTemplateDay(templateDayData: $templateDayData) {
  		templateDay {
        id
        dayNumber
        templatePlan {
          id
        }
        mealSet {
          id
  				mealType
          recipes {
            id
          }
        }
      }
    }
  }
`

export const CREATE_MEAL = gql`
  mutation CreateMeal($mealData: MealInput!) {
    createMeal(mealData: $mealData) {
      meal {
        id
        templateDay {
          id
        }
        mealType
        recipes {
          id
        }
      }
    }
  }
`


export const CREATE_REVIEW = gql`
  mutation CreateReview($reviewData: ReviewInput!) {
    createReview(reviewData: $reviewData) {
      review {
        id
        recipe {
          id
          name
        }
        profile {
          id
          user {
            id
            firstName
            lastName
          }
        }
        rating
        comment
      }
    }
  }
`


export const CREATE_RECIPE = gql`
  mutation CreateRecipe($recipeData: CreateRecipeInput!) {
    createRecipe(recipeData: $recipeData) {
      recipe {
        id
      }
    }
  }
`


export const ADD_MEALS = gql`
  mutation AddMeals($mealPlanData: MealPlanInput!) {
    addMeals(mealPlanData: $mealPlanData) {
  		mealPlan {
        id
      }
    }
  }
`

export const ADD_RECIPES = gql`
  mutation AddRecipes($recipeData: RecipesInput!, $mealData: MealInput!) {
    addRecipes(recipeData: $recipeData, mealData: $mealData) {
  		recipe {
        id
        name
      }
      meal {
        id
        mealType
        mealPlan {
          id
        }
      	templateDay {
          id
          dayNumber
          templatePlan {
            id
            name
          }
        }
      }
    }
  }
`

export const ADD_DAY_MEALS = gql`
  mutation AddDayMeals($mealsData: DayMealsInput!) {
    addDayMeals(mealsData: $mealsData) {
      mealPlan {
        id
        planDate
        mealSet {
          id
          name
          recipes {
            id
            name
          }
        }
      }
    }
  }
`


export const DELETE_MEALS = gql`
  mutation DeleteMeals($mealPlanData: MealPlanInput!) {
    deleteMeals(mealPlanData: $mealPlanData) {
      mealPlan {
        id
      }
    }
  }
`

export const DELETE_DAY_MEALS = gql`
  mutation DeleteDayMeals($mealPlanId: ID!, $mealDate: Date!) {
    deleteDayMeals(mealPlanId: $mealPlanId, mealDate: $mealDate) {
      mealPlan {
        id
      }
    }
  }
`

export const DELETE_DAY = gql`
  mutation CreateTemplateDay($templateDayData: TemplateDayInput!) {
    createTemplateDay(templateDayData: $templateDayData) {
  		templateDay {
        id
        dayNumber
        templatePlan {
          id
        }
        mealSet {
          id
  				mealType
          recipes {
            id
          }
        }
      }
    }
  }
`

export const DELETE_TEMPLATE_DAY = gql`
  mutation DeleteTemplateDay($templateDayData: TemplateDayInput!) {
    deleteTemplateDay(templateDayData: $templateDayData) {
      templateDay {
        id
      }
    }
  }
`

export const DELETE_TEMPLATE_PLAN = gql`
  mutation DeleteTemplatePlan($templatePlanData: TemplatePlanInput!) {
    deleteTemplatePlan(templatePlanData: $templatePlanData) {
      templatePlan {
        id
        name
        description
      }
    }
  }
`

export const DELETE_MEAL_PLAN = gql`
  mutation DeleteMealPlan($mealPlanData: MealPlanInput!) {
    deleteMealPlan(mealPlanData: $mealPlanData) {
      mealPlan {
        id
      }
    }
  }
`

export const DELETE_ALLERGENS = gql`
  mutation DeleteAllergens($allergenIds: [ID!]) {
    deleteAllergens(allergens: $allergenIds) {
      allergens {
        id
      }
    }
  }
`
