import React, { useState } from 'react'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faCheck } from '@fortawesome/free-solid-svg-icons'

import { DAY, WEEK, TWO_WEEK, MONTH, TYPES, DATE_FORMAT } from '../../api/constants.js'

import * as queries from '../../api/queries.js'
import DayMeals from './calendar/DayMeals'
import WeekMeals from './calendar/WeekMeals'
import MonthMeals from './calendar/MonthMeals'

import { useApi } from '../../contexts/api'
import { LtDate } from  '../forms'
import { BlueBtn } from '../btns'

const getCalendarObject = (startDate, endDate, month, interval) => {
  var current = moment(startDate).startOf('week')
  var end = moment(endDate).endOf('week')
  let weeks = []
  const title = {
    [DAY]: `${moment(current).format('MMM D')} - ${moment(end).format('MMM D')}`,
    [WEEK]: `${moment(startDate).format('MMM D')} - ${moment(endDate).format('MMM D')}`,
    [TWO_WEEK]: `${moment(startDate).format('MMM D')} - ${moment(endDate).format('MMM D')}`,
    [MONTH]: moment(startDate).format('MMMM YYYY')
  }
  while (current <= end) {
    weeks.push(moment(current))
    current.add(7, 'd')
  }

  const weeksData = weeks.map((weekStart) => {
    let days = []
    let i = 0
    while (i < 7) {
      const day = moment(weekStart).add(i, 'd').format(DATE_FORMAT)
      const tomorrow = moment(weekStart).add(i + 1, 'd').format(DATE_FORMAT)
      const prep = month[tomorrow] ? month[tomorrow].some(m => !m.leftovers && m.recipes.some(r => r.overnightPrep)) : false
      if (month[day] != undefined) {
        days.push({
          num: moment(day).format('D'),
          prep: prep,
          meals: month[day].map((value) => {
            const recipes = value.recipes.map((recipe) => {
              return {name: recipe.name, overnightPrep: recipe.overnightPrep}
            })
            return {
              type: TYPES[value.mealType],
              name: value.name,
              recipes: recipes,
              leftovers: value.leftovers
            }
          })
        })
      } else {
        days.push({
          num: moment(weekStart).add(i, 'd').format('D'),
          meals: []
        })
      }
      i += 1
    }
    return {days: days}
  })
  return {
    calendar: {
      title: title[interval],
      weeks: weeksData
    }
  }
}

const isDateRangeEmpty = (start, end, data) => {
  const startDate = moment(start)
  const endDate = moment(end)
  while (startDate <= endDate) {
    if (data[moment(startDate).format(DATE_FORMAT)] != undefined) {
      return false
    } else {
      startDate.add(1, 'd')
    }
  }

  return true
}

// Controls what calendar view is displayed
const Calendar = ({ dayOffset, setDayOffset, familyId }) => {
  const api = useApi()
  const selectedDate = moment().add(dayOffset, 'd')
  const [calendarView, setCalendarView] = useState(DAY)
  const [calendarFetching, setCalendarFetching] = useState(false)
  const [mealPlanFetching, setMealPlanFetching] = useState(false)
  const [editMode, setEditMode] = useState(false)

  const dateRange = () => {
    const first = moment(selectedDate).startOf('month').startOf('week')
    const last = moment(selectedDate).endOf('month').endOf('week')
    return [first, last]
  }
  const range = dateRange()
  const [startDate, setStartDate] = useState(moment(selectedDate))
  const [endDate, setEndDate] = useState(moment(selectedDate))

  const [createMealPlan, mealPlanData] = api.useMutation(queries.AUTO_GEN_MEAL_PLAN, {
    variables: {
      familyId: familyId,
      startDate: moment(startDate).format(DATE_FORMAT),
      numDays: endDate.diff(startDate, 'days') + 1
    },
    refetchQueries: () => [{query: queries.FAMILY_CALENDAR, variables: {
      familyId: familyId,
      startDate: moment(range[0]).format(DATE_FORMAT),
      endDate: moment(range[1]).format(DATE_FORMAT)
    }}, {query: queries.CUSTOM_MEAL_PLAN_JSON, variables: {
      familyId: familyId,
      startDate: moment(startDate).format(DATE_FORMAT),
      endDate: moment(endDate).format(DATE_FORMAT)
    }}],
  })

  const { loading, error, data } = api.useQuery(queries.FAMILY_CALENDAR, {
    variables: {
      familyId: familyId,
      startDate: moment(range[0]).format(DATE_FORMAT),
      endDate: moment(range[1]).format(DATE_FORMAT)
    }
  })

  const mealsData = api.useQuery(queries.CUSTOM_MEAL_PLAN_JSON, {
    variables: {
      familyId: familyId,
      startDate: moment(startDate).format(DATE_FORMAT),
      endDate: moment(endDate).format(DATE_FORMAT)
    }
  })

  if (error) return <p>Error: {error.message}</p>

  let month = {}
  if (!loading) {
    const mealPlans = data.mealplansFromDateRange
    var plan
    for (plan of mealPlans) {
      const date = plan.planDate
      for (let i = 0; i < plan.mealSet.length; i += 3) {
        month[moment(date).add((i / 3), 'd').format(DATE_FORMAT)] = [
          plan.mealSet[i],
          plan.mealSet[i+1],
          plan.mealSet[i+2],
        ]
      }
    }
  }

  const viewData = {
    dayOffset: dayOffset,
    setDayOffset: setDayOffset,
    selectedDate: selectedDate,
    setCalendarView: setCalendarView,
    data: month,
    loading: loading,
    familyId: familyId,
    api: api,
    range: range,
    editMode: editMode,
    startDate: startDate,
    setStartDate: setStartDate,
    endDate: endDate,
    setEndDate: setEndDate
  }

  const currentView = {
    [DAY]: <DayMeals {...viewData} />,
    [WEEK]: <WeekMeals type={WEEK} {...viewData} />,
    [TWO_WEEK]: <WeekMeals type={TWO_WEEK} {...viewData} />,
    [MONTH]: <MonthMeals {...viewData} />,
  }

  return (
    <div className='calendar'>
      <form className="create-mealplan" autoComplete="off">
        <LtDate
          name="startDate"
          label="Start Date"
          value={startDate}
          onChange={(e) => {
            if (moment(e.target.value) > endDate) {
              setEndDate(moment(e.target.value).add(endDate.diff(startDate, 'days'), 'd'))
            }
            setStartDate(moment(e.target.value))
          }}
        />
        <LtDate
          name="endDate"
          label="End Date"
          value={endDate}
          onChange={(e) => {
            if (moment(e.target.value) < startDate) {
              setStartDate(moment(e.target.value).add(startDate.diff(endDate, 'days'), 'd'))
            }
            setEndDate(moment(e.target.value))
          }}
        />
        <BlueBtn
          onClick={() => createMealPlan()}
          label='Auto-Populate Meal Plan'
          isRunning={loading || mealPlanData.loading}
          className='flex-1 small'
          disabled={loading || mealPlanData.loading || !isDateRangeEmpty(startDate, endDate, month)}
        />
        <BlueBtn
          onClick={async () => {
            setMealPlanFetching(true)
            await api.getCustomMealPlan(mealsData.data.customMealPlanJson)
            setMealPlanFetching(false)
          }}
          label='Print Recipes'
          isRunning={mealsData.loading || mealPlanFetching}
          className='flex-1 small spacer'
          disabled={mealsData.loading || mealPlanFetching || isDateRangeEmpty(startDate, endDate, month)}
        />
        <BlueBtn
          onClick={async () => {
            setCalendarFetching(true)
            await api.getCalendar(
              getCalendarObject(startDate, endDate, month, calendarView)
            )
            setCalendarFetching(false)
          }}
          label='Print Calendar'
          isRunning={calendarFetching}
          className='flex-1 small'
          disabled={calendarFetching}
        />
      </form>
      <div className='header-calendar-picker'>
        <button
          onClick={() => {
            setCalendarView(DAY)
            setStartDate(selectedDate)
            setEndDate(selectedDate)
          }}
          className={calendarView == DAY ? 'header-calendar-picker_active' : 'header-calendar-picker_inactive'}
        >
          Day
        </button>
        <button
          onClick={() => {
            setCalendarView(WEEK)
            setStartDate(moment(selectedDate).startOf('week'))
            setEndDate(moment(selectedDate).endOf('week'))
          }}
          className={calendarView == WEEK ? 'header-calendar-picker_active' : 'header-calendar-picker_inactive'}
        >
          Week
        </button>
        <button
          onClick={() => {
            setCalendarView(TWO_WEEK)
            setStartDate(moment(selectedDate).startOf('week'))
            setEndDate(moment(selectedDate).add(7, 'd').endOf('week'))
          }}
          className={calendarView == TWO_WEEK ? 'header-calendar-picker_active' : 'header-calendar-picker_inactive'}
        >
          2 Week
        </button>
        <button
          onClick={() => {
            setCalendarView(MONTH)
            setStartDate(moment(selectedDate).startOf('month'))
            setEndDate(moment(selectedDate).endOf('month'))
          }}
          className={calendarView == MONTH ? 'header-calendar-picker_active' : 'header-calendar-picker_inactive'}
        >
          Month
        </button>
        <button
          onClick={() => setEditMode(!editMode)}
          className="header-calendar-picker_inactive"
        >
          <FontAwesomeIcon className="toggle-edit" icon={editMode ? faCheck : faEdit} />
        </button>
      </div>
      {currentView[calendarView]}
    </div>
  )
}
export default Calendar
