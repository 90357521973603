import React, { Component } from 'react'

export class Loading extends Component {
  render() {
    return (
      <div className="main-spinner">
        <div className="dbl-spinner"></div>
        <div className="dbl-spinner"></div>
      </div>
    )
  }
}
