import React, { useState } from 'react'
import { useApi } from '../contexts/api'
import { useParams } from 'react-router-dom'
import { Link } from 'react-router-dom'

import FamilyMembers from '../components/details/FamilyMembers.js'
import Calendar from '../components/details/Calendar.js'
import { Loading } from '../components/loading'

import * as queries from '../api/queries.js'

// see family members (link to pages)
// see aggregate allergens
// see banned recipes?

const Family = () => {
  const api = useApi()
  const { id } = useParams()

  const [dayOffset, setDayOffset] = useState(0)

  const { loading, error, data } = api.useQuery(queries.FAMILY, {
    variables: {
      id: id,
    }
  })

  if (loading) {
    return <Loading />
  }
  if (error) return <p>Error: {error.message}</p>

  return (
    <div className="Main family">
      <div className="family-header">
        <div className="family-header_inner">
          <h2>{data.family.lastName + ' Family'}</h2>
          <div className="link">
            <Link to={'/shoppinglist/' + id}>Shopping List</Link>
          </div>
        </div>
        <FamilyMembers profiles={data.family.profileSet} />
      </div>
      <div className="profile">
        <div className="family-template">
          <Calendar familyId={id} setDayOffset={setDayOffset} dayOffset={dayOffset} />
        </div>
      </div>
    </div>
  )
}

export {Family}
