import '@babel/polyfill'

import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { AppProviders } from './js/contexts'
import { Nav, ProtectedRoute, UnprotectedRoute, CustomRedirect } from './js/components'
import {
  Family,
  RecipeScreen,
  RecipeList,
  RecipeSubmit,
  Patient,
  Dashboard,
  Plans,
  Setup,
  Login,
  Logout,
  SignUp,
  ShoppingListScreen
} from './js/screens'
import './scss/app.scss'





ReactDOM.render(
  <AppProviders>
    <Router>
      <Nav>
        <Switch>
          <UnprotectedRoute exact path='/login' component={Login} />
          <UnprotectedRoute exact path='/logout' component={Logout} />
          <UnprotectedRoute exact path='/register' component={SignUp} />
          <ProtectedRoute exact path='/dashboard/:id' permission='nextlevel.view_profile' objectType='nextlevel.Profile' component={Dashboard} />
          <ProtectedRoute exact path='/setup/:id' permission='nextlevel.view_profile' objectType='nextlevel.Profile' component={Setup} />
          <ProtectedRoute exact path='/plans' permission='nextlevel.view_staff_dashboard' component={Plans} />
          <ProtectedRoute exact path='/family/:id' permission='nextlevel.view_family' objectType='nextlevel.Family' component={Family} />
          <ProtectedRoute exact path='/patient/:id' permission='nextlevel.view_profile' objectType='nextlevel.Profile' component={Patient} />
          <ProtectedRoute exact path='/recipe/:id' permission='nextlevel.view_recipe' component={RecipeScreen} />
          <ProtectedRoute exact path='/recipes' permission='nextlevel.view_recipe' component={RecipeList} />
          <ProtectedRoute exact path='/recipe-submit' permission='nextlevel.view_recipe' component={RecipeSubmit} />
          <ProtectedRoute exact path="/shoppinglist/:id" permission='nextlevel.view_family' objectType='nextlevel.Family' component={ShoppingListScreen} />
          <Route exact path='/dashboard' ><CustomRedirect withId newPath='/dashboard' /></Route>
          <Route exact path='/setup' ><CustomRedirect withId newPath='/setup' /></Route>
          <Route exact path='/'><CustomRedirect newPath='/login' /></Route>
        </Switch>
      </Nav>
    </Router>
  </AppProviders>,
  document.getElementById('app')
)
