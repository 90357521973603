import React from 'react'
import PropTypes from 'prop-types'


const LtInput = ({
  name,
  label,
  value,
  error,
  onChange,
  placeholder,
  checked,
  type,
  className,
  min
}) => {
  return (
    <div className={`lt-form-element ${className} ${checked} ${error && 'error'}`}>
      <label htmlFor={name}>
        {label} <span className="input-error">{error}</span>
      </label>
      <input
        id={name}
        name={name}
        type={type}
        value={value}
        onChange={onChange}
        checked={checked}
        placeholder={placeholder}
        min={min}
      />

    </div>
  )
}

LtInput.defaultProps = {
  type: 'text',
  className: '',
}

LtInput.propTypes = {
  name: PropTypes.string,
  className: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  error: PropTypes.string,
  type: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  checked: PropTypes.bool,
}

export { LtInput }
