import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'
import { Link } from 'react-router-dom'

import Meal from '../../plan/Meal'
import { Loading } from '../../loading'
import { MutationIcon } from './mutationBtn'
import { TYPES, DATE_FORMAT } from '../../../api/constants.js'
import * as queries from '../../../api/queries.js'


const prepWarning = (text, data) => {
  let prepList = []
  if (data != undefined) {
    data.forEach(meal => {
      meal.recipes.forEach((recipe) => {
        if (recipe.overnightPrep && !meal.leftovers) {
          prepList.push(
            <div key={recipe.id} className="alert alert-warning">
              <FontAwesomeIcon className="exclamation" icon={faExclamationTriangle} />
              {text}&apos;s {TYPES[meal.mealType]} may need overnight preparation for&nbsp;
              <Link className="alert__link" to={`/recipe/${recipe.id}`}>{recipe.name}</Link>
            </div>
          )
        }
      })
    })
  }
  return prepList
}

const DayMeals = ({ dayOffset, setDayOffset, selectedDate, api, ...props }) => {
  const refetchQueries = [{
    query: queries.FAMILY_CALENDAR,
    variables: {
      familyId: props.familyId,
      startDate: moment(props.range[0]).format(DATE_FORMAT),
      endDate: moment(props.range[1]).format(DATE_FORMAT)
    }
  }, {
    query: queries.CUSTOM_MEAL_PLAN_JSON,
    variables: {
      familyId: props.familyId,
      startDate: moment(props.startDate).format(DATE_FORMAT),
      endDate: moment(props.endDate).format(DATE_FORMAT)
    }
  }]
  const [deleteDayMeals, deleteDayMealsData] = api.useMutation(queries.DELETE_DAY_MEALS, {
    refetchQueries: () => refetchQueries
  })
  const [addDayMeals, addDayMealsData] = api.useMutation(queries.ADD_DAY_MEALS, {
    refetchQueries: () => refetchQueries
  })
  const [updateMeal] = api.useMutation(queries.UPDATE_MEAL, {
    refetchQueries: () => refetchQueries
  })

  let displayDate = selectedDate.format('MMMM D')

  if (dayOffset == 0) {
    displayDate = 'Today'
  } else if (dayOffset == 1) {
    displayDate = 'Tomorrow'
  } else if (dayOffset == -1) {
    displayDate = 'Yesterday'
  }


  var meals
  if (props.data[selectedDate.format(DATE_FORMAT)] != undefined) {
    meals = props.data[selectedDate.format(DATE_FORMAT)].map((value, index) => {
      return (
        <Meal
          name={value.name}
          key={index}
          type={TYPES[value.mealType]}
          mealRecipes={value.recipes}
          leftovers={value.leftovers}
          day={null}
          date={moment(selectedDate)}
          mealPlanId={value.mealPlan.id}
          mealId={value.id}
          dayId={null}
          updateMeal={updateMeal}
          dayOffset={dayOffset}
          setDayOffset={setDayOffset}
          {...props}
        />
      )
    })
  }

  const tomorrow = moment(selectedDate).add(1, 'd').format(DATE_FORMAT)

  return (<div>
    <div className='header-date-step'>
      <button className='back' onClick={() => {
        setDayOffset(dayOffset - 1)
        const newDay = moment(selectedDate).add(-1, 'd')
        props.setStartDate(newDay)
        props.setEndDate(newDay)
      }}><FontAwesomeIcon icon={faChevronLeft}/></button>
      <button className='back' onClick={() => {
        setDayOffset(0)
        const newDay = moment()
        props.setStartDate(newDay)
        props.setEndDate(newDay)
      }}>Today</button>
      <button className='forward' onClick={() => {
        setDayOffset(dayOffset + 1)
        const newDay = moment(selectedDate).add(1, 'd')
        props.setStartDate(newDay)
        props.setEndDate(newDay)
      }}><FontAwesomeIcon icon={faChevronRight}/></button>
      <h2>{displayDate}</h2>
    </div>
    <div>
      <div>
        {prepWarning('Today', props.data[selectedDate.format(DATE_FORMAT)])}
        {props.editMode ? (
          <div className='mutation'>
            <MutationIcon
              formattedDate={selectedDate.format(DATE_FORMAT)}
              previousDate={moment(selectedDate).add(-1, 'd').format(DATE_FORMAT)}
              data={props.data}
              familyId={props.familyId}
              addDayMeals={addDayMeals}
              addDayMealsData={addDayMealsData}
              deleteDayMeals={deleteDayMeals}
              deleteDayMealsData={deleteDayMealsData}
              className='meals-calendar-day__day-button'
            />
          </div>
        ) : ''}
      </div>
      <div className='meals-body'>
        {props.loading ? <Loading /> : meals != undefined && meals.length > 0 ? meals : <h2>No meals planned for {selectedDate.format('MMMM D')}</h2>}
      </div>
      <div>{prepWarning('Tomorrow', props.data[tomorrow])}</div>
    </div>
  </div>
  )
}
export default DayMeals
