import React from 'react'

const FrequencyLabel = (data) => {
  switch (data.data) {
    case '1x_week':
      return 'Once per Week'
    case '2x_month':
      return 'Twice per Month'
    case '1x_month':
      return 'Once per Month'
    default:
      return null
  }
}
export {FrequencyLabel}
