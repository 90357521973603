import React from 'react'
import { AllTemplates } from '../components/plan/AllTemplates'
import { useQuery } from '@apollo/react-hooks'
import { Loading } from '../components/loading'

import * as queries from '../api/queries.js'

const Plans = () => {
  const { loading, error } = useQuery(queries.DB_TEMPLATES)

  if (loading) return <Loading />
  if (error) return <p>Error :(</p>


  return (
    <div className="Main mealtemplate">
      <AllTemplates />
    </div>
  )
}

export {Plans}
