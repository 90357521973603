import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle, faEdit, faCheck } from '@fortawesome/free-solid-svg-icons'
import { useParams } from 'react-router-dom'
import { LtSelect } from  '../forms'
import { Spinner } from '../loaders/Spinner'
import { BlueBtn } from '../btns'
import { useApi } from '../../contexts/api'
import { STRENGTH, EXPIRATION } from '../../api/constants'
import * as queries from '../../api/queries'
import moment from 'moment'

const Allergen = (props) => {
  const allergens = props.allergens.map((allergen, index) => {
    let btn = null
    const expirationDate = moment(allergen.createdAt).add(EXPIRATION[allergen.sensitivity], 'M').startOf('day')
    if (expirationDate <= moment().startOf('day')) {
      btn = (
        <button className="icon" onClick={() => props.deleteAllergens({variables: {allergenIds: [allergen.id]}})}>
          <FontAwesomeIcon className="small" icon={faTimesCircle} />
        </button>
      )
    } else if (allergen.sensitivity != 4) {
      const difference = expirationDate.diff(moment().startOf('day'), 'days')
      btn = (
        <span className={`countdown ${STRENGTH[allergen.sensitivity]}`}>{difference} {difference != 1 ? 'days' : 'day'}</span>
      )
    }
    return (
      <li key={index}>
        {allergen.ingredient.name} {props.editMode ? btn : null}
      </li>
    )
  })
  if (allergens.length) {
    return ( <div className="table table--collapse">
      <div className={`table-cell table-cell--head ${props.allergenGroup.toLowerCase()}`}><h3>{props.allergenGroup}</h3></div>
      <div className="table-cell"><ul>{allergens}</ul></div>
    </div>
    )
  } else {
    return null
  }
}

const AddAllergen = ({ data, createAllergen, profileId, ...props }) => {
  const [value, setValue] = useState(null)
  if (data.potentialDislikes) {

    const selectIngredients = data.potentialDislikes.map((ingredient) => {
      return { label:ingredient.name, value: ingredient.id }
    })
    return (
      <div className="add-dislike">
        <LtSelect
          name="potentialDislikes"
          label="Add Dislikes"
          placeholder="Add Dislikes"
          options={selectIngredients}
          value={value}
          onChange={(e) => {
            setValue(e.target.value)
            createAllergen({
              variables: {
                allergenData: {
                  profileId: profileId,
                  sensitivity: 4,
                  ingredient: {
                    id: e.target.value.value,
                    name: e.target.value.label
                  }
                }
              }
            })
          }}
        />
      </div>
    )
  }
}

const Allergens = (props) => {
  const [editMode, setEditMode] = useState(false)

  let mildAllergens = []
  let moderateAllergens = []
  let severeAllergens = []
  let dislikes = []
  const allergens = props.allAllergens.map((allergen) => {
    switch (STRENGTH[allergen.sensitivity].toLowerCase()) {
      case 'mild':
        return mildAllergens.push(allergen)
      case 'moderate':
        return moderateAllergens.push(allergen)
      case 'severe':
        return severeAllergens.push(allergen)
      case 'dislike':
        return dislikes.push(allergen)
      default:
        return null
    }
  })
  return (<>
    <div  className="allergens-wrapper">
      <div className="allergens-header">
        <h2>
          Allergens
        </h2>
        <button
          onClick={() => {
            if (editMode) {
              props.updateAllergens()
            }
            setEditMode(!editMode)
          }}
          className="edit-mode"
        >
          {props.updateAllergensData.loading ? <Spinner className="spinner" /> : <FontAwesomeIcon className="toggle-edit" icon={editMode ? faCheck : faEdit} />}
        </button>
      </div>
      <div className="allergens">
        {allergens.length ? '' : (<h3 className="empty">No allergens</h3>)}
        <Allergen allergenGroup='Mild' allergens={mildAllergens} editMode={editMode} {...props} />
        <Allergen allergenGroup='Moderate' allergens={moderateAllergens} editMode={editMode} {...props} />
        <Allergen allergenGroup='Severe' allergens={severeAllergens} editMode={editMode} {...props} />
      </div>
    </div>
    <div className="dislikes">
      <div className="allergens-header">
        {dislikes.length || editMode ? (<h2>Dislikes</h2>) : ''}
        {props.createAllergenData.loading || props.deleteAllergensData.loading ? (
          <div className="spinner-holder">
            <Spinner className="spinner" />
          </div>
        ) : ''}
      </div>
      <div className="allergens">
        {editMode ? <AddAllergen {...props} /> : null}
        <Allergen allergenGroup='' allergens={dislikes} editMode={editMode} {...props} />
      </div>
    </div>
  </>
  )
}

export { Allergens, Allergen }
