import React, { useState } from 'react'
import { useApi } from '../contexts/api'
import { useParams } from 'react-router-dom'
import { Link } from 'react-router-dom'

import Rating from 'react-rating'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar, faPlus, faPrint } from '@fortawesome/free-solid-svg-icons'
import logo from '../../images/logo.png'
import Review from '../components/details/Review.js'
import { Loading } from '../components/loading'
import { LtSelect } from  '../components/forms'
import { Spinner } from '../components/loaders/Spinner.js'

import * as queries from '../api/queries'
import { CLOCK, SPRING, SUMMER, FALL, WINTER } from '../components/dataImgs'

const seasonImgs = {
  'Spring': SPRING,
  'Summer': SUMMER,
  'Fall': FALL,
  'Winter': WINTER
}

const Instruction = ({ description, step }) => {
  return (
    <tr className="instructions">
      <td className="instruction">
        <div className="step">{ step ? step : '' }</div>
      </td>
      <td className="half light">
        { description ? description : '' }
      </td>
    </tr>
  )
}

const Ingredient = ({ ingredient, replacement, size, unit, modifier }) => {
  var name = ingredient
  if (replacement) {
    name = <span><del>{ingredient}</del> - {replacement}</span>
  }
  return (
    <li>
      <span className="measurement">
        {size ? <><span dangerouslySetInnerHTML={{__html: size}} />&nbsp;</> : ''}
        {unit ? <span>{unit}&nbsp;</span> : ''}
      </span>
      <span className="ingredient">
        {name}
        {modifier ? <span>&nbsp;({modifier})</span> : ''}
      </span>
    </li>
  )
}



const RecipeScreen = () => {
  const api = useApi()
  const { id } = useParams()

  let userComment = null
  let userRating = null
  const [fetching, setFetching] = useState(false)

  const [createComment, commentMutationData] = api.useMutation(queries.CREATE_REVIEW, {
    refetchQueries: () => [{query: queries.RECIPE, variables: { id: id }}]
  })

  const { loading, error, data } = api.useQuery(queries.RECIPE, {
    variables: {
      id: id
    }
  })

  if(loading) {
    return <Loading />
  }

  if (error) {
    console.log(error)
    return error
  }

  const ingredients = data.recipe.recipeIngredients.map(({
    id,
    portionSize,
    portionUnit,
    ingredient,
    replacement,
    modifier
  }) => (
    <Ingredient
      key={id}
      id={id}
      size={portionSize}
      unit={portionUnit}
      ingredient={ingredient.name}
      replacement={replacement}
      modifier={modifier}
    />
  ))
  const instructions = data.recipe.instructions.map(({
    id,
    step,
    description,
    duration
  }) => (
    <Instruction
      key={id}
      id={id}
      step={step}
      description={description}
      duration={duration}
    />
  ))

  const reviews = [].concat(data.reviews)
    .sort((a) => a.profile.id == data.me.profile.id  ? -1 : 1)
    .map(({ profile, rating, comment }, index) => {
      if (profile.id == data.me.profile.id) {
        userComment = comment
        userRating = rating
      } else {
        return (
          <Review
            key={index}
            profileId={profile.id}
            firstName={profile.user.firstName}
            lastName={profile.user.lastName}
            rating={rating}
            comment={comment}
            editable={profile.id == data.me.profile.id}
            recipeId={id}
          />
        )
      }
    })

  let recipeImage = null
  if (data.recipe.image) {
    recipeImage = <img className="center-cropped" src={data.recipe.image} />
  }

  const seasons = data.recipe.season.map(({name}, index) => (
    <img key={index} className="season" src={seasonImgs[name]} />
  ))


  return (
    <div>
      <div className="single-recipe">
        <div className="inner-wrapper">
          <div className="section recipe-header">
            <div className="title bold">
              <div className="title-wrapper">
                {data.recipe.name}
                {fetching ? <Spinner className="dark" /> : (
                  <FontAwesomeIcon icon={faPrint} onClick={async () => {
                    setFetching(true)
                    await api.getRecipe(data.recipe.id, 'PDF')
                    setFetching(false)
                  }}/>
                )}
              </div>
              {recipeImage}
              <div className="subheader">
                <div className="submitted-by">Recipe by: Next Level Health</div>
                <div className="rating">
                  <Rating
                    emptySymbol={<FontAwesomeIcon style={{ color: '#D3D6DB' }} icon={faStar} />}
                    fullSymbol={<FontAwesomeIcon style={{ color: '#f4a80f' }} icon={faStar} />}
                    initialRating={data.recipe.rating}
                    fractions={10}
                    readonly={true}
                  />
                  <p>{data.recipe.rating ? data.recipe.rating : 0} stars / {data.reviews.length} reviews</p>
                </div>
              </div>
              <div className="description">{data.recipe.description}</div>
            </div>
          </div>

          <hr />

          <div className="info-section">
            <div className="info first">
              <img className="season" src={CLOCK} /></div>
            <div className="info first">
              <span className="info-top">prep</span><br />
              <span className="info-bottom">{ parseInt(data.recipe.prepTime / 60) } m</span>
            </div>
            <div className="info">
              <span className="info-top">cook</span><br />
              <span className="info-bottom">{ parseInt(data.recipe.cookTime / 60) } m</span>
            </div>
            <div className="info">
              {seasons}
            </div>
            <div className="info-right">
              <div className="info first">
                <span className="info-top">servings</span><br />
                <span className="info-bottom">{ data.recipe.servings }</span>
              </div>
              {data.recipe.servingSize ? (
                <div className="info last">
                  <span className="info-top">serving size</span><br />
                  <span className="info-bottom">{ data.recipe.servingSize } {data.recipe.sizeUnit }</span>
                </div>
              ) : ''}
            </div>
          </div>

          <div className="general-info">
            <div className="sub-section">
              <div className="recipe-section-header">Ingredients</div>
              <div className="ingredients">
                <ul>
                  {ingredients}
                </ul>
              </div>
            </div>
            <div className="sub-section instructions">
              <div className="recipe-section-header">Instructions</div>
              <table className="instructions">
                <tbody>
                  {instructions}
                </tbody>
              </table>
            </div>
          </div>
          <Review
            profileId={data.me.profile.id}
            firstName={data.me.firstName}
            lastName={data.me.lastName}
            comment={userComment}
            rating={userRating}
            editable={true}
            recipeId={id}
            createComment={createComment}
            loading={commentMutationData.loading}
          />
          {reviews}
        </div>
      </div>
    </div>
  )
}

const Tag = ({ name, list, setList }) => {
  const [selected, setSelected] = useState(false)

  const addToList = () => {
    setList([name, ...list])
  }

  const removeFromList = () => {
    const newList = [...list]
    const index = newList.indexOf(name)
    if (index !== -1) {
      newList.splice(index, 1)
      setList(newList)
    }
  }

  return (
    <button
      className="tag"
      onClick={() => {
        if (selected) {
          removeFromList()
        } else {
          addToList()
        }
        setSelected(!selected)
      }}>
      <div className={`${selected ? '' : 'not-'}selected`}>
        {name}
      </div>
    </button>
  )
}

const Queried = ({ api, tags, foods, seasons, ...props }) => {
  const [recipeSearch, setRecipeSearch] = useState()
  const { loading, data } = api.useQuery(queries.FILTERED_RECIPES, {
    variables: {
      tags: tags,
      foods: foods,
      seasons: seasons
    },
  })

  if (loading) {
    return <Loading />
  }

  const recipeSelect = data.filteredRecipes.map((recipe) => (
    { label: (
      <Link to={'/recipe/' + recipe.id}>
        {recipe.image ? <img href={recipe.image} /> : <div href={logo} /> }
        {recipe.name}
      </Link>
    ), value: recipe.name }
  ))

  const recipesList = data.filteredRecipes.map(recipe => (
    <Link key={recipe.id} to={'/recipe/' + recipe.id} className="recipe-wrapper">
      {recipe.image ? (
        <div style={{ backgroundImage: `url(${recipe.image})`, }} className="thumbnail-image"></div>
      ) : (
        <div style={{ backgroundImage: `url(${logo})`, }} className="thumbnail-image placeholder"></div>
      )}
      <div className="recipe-info">
        <h3 className="thumbnail-name">{recipe.name}</h3>
        <Rating
          className="thumbnail-rating"
          emptySymbol={<FontAwesomeIcon style={{ color: '#D3D6DB' }} icon={faStar} />}
          fullSymbol={<FontAwesomeIcon style={{ color: '#f4a80f' }} icon={faStar} />}
          initialRating={recipe.rating}
          fractions={10}
          readonly={true}
        />
      </div>
    </Link>
  ))

  return (
    <div className="list-wrapper recipes-page">
      <h2>
        Recipes ({data.filteredRecipes.length})
        <Link to="/recipe-submit" target="_blank">
          <FontAwesomeIcon icon={faPlus} />
        </Link>
      </h2>
      <LtSelect
        className="select-menu"
        name="ingredientFilters"
        options={recipeSelect}
        value={recipeSearch}
        onChange={(e) => {
          setRecipeSearch(e.target.value)
        }}
      />
      <div className="recipe-list list">
        {recipesList}
      </div>
    </div>
  )
}

const RecipeList = () => {
  const api = useApi()

  const [recipeTagsList, setRecipeTagsList] = useState([])
  const [ingredientTagsList, setIngredientTagsList] = useState([])
  const [seasonList, setSeasonList] = useState([])
  const [ingredientSearch, setIngredientSearch] = useState()

  const { loading, data } = api.useQuery(queries.FILTERS)

  if (loading) {
    return <Loading />
  }

  const recipeTags = data.allRecipeTags.map(({ id, name }) => (
    <Tag key={id} name={name} list={recipeTagsList} setList={setRecipeTagsList} />
  ))
  let ingredientTags = []
  const selectIngredientTags = data.allIngredientTags.map(({ id, name }) => {
    return {
      label: name,
      value: <Tag key={id} name={name} list={ingredientTagsList} setList={setIngredientTagsList} />
    }
  })
  const seasonTags = data.allSeasons.map(({ id, name }) => (
    <Tag key={id} name={name} list={seasonList} setList={setSeasonList} />
  ))

  const removeFromIngredientsList = (name) => {
    const newList = [...ingredientTagsList]
    const index = newList.indexOf(name)
    if (index !== -1) {
      newList.splice(index, 1)
      setIngredientTagsList(newList)
    }
  }

  const displayTags = ingredientTagsList.map((name, index) => {
    return (
      <button
        key={index}
        className="tag"
        onClick={() => removeFromIngredientsList(name)}
      >
        <div className="not-selected">
          {name}
        </div>
      </button>
    )
  })

  return (
    <div className="staff-dashboard">
      <div className="column">
        <Queried
          api={api}
          tags={recipeTagsList}
          foods={ingredientTagsList}
          seasons={seasonList}
        />
      </div>
      <div className="column recipe-filters">
        <div className="list-wrapper">
          <h2>Recipe Filters</h2>
          <div className="tags">
            {recipeTags}
          </div>
          <h2>Ingredient Filters</h2>
          <LtSelect
            name="ingredientFilters"
            options={selectIngredientTags}
            value={ingredientSearch}
            onChange={(e) => {
              const name = e.target.value.label
              if (ingredientTagsList.indexOf(name) === -1) {
                setIngredientTagsList([name, ...ingredientTagsList])
              }
              setIngredientSearch({label: 'Select...', value: ''})
            }}
          />
          <div className="tags">
            {displayTags}
          </div>
          <h2>Seasons</h2>
          <div className="tags">
            {seasonTags}
          </div>
        </div>
      </div>
    </div>
  )
}

export { RecipeList, RecipeScreen }
