import React from 'react';
import PropTypes from 'prop-types';
import { Spinner } from './loaders/Spinner.js';
import Help from '../../images/help_icon.svg';


/**
 * Props
 *    label: HTML element, React Element, or string isRequired
 *    onClick: callback funtion
 *    className: class to override spinner styles
 *    isRunning: show Spinner element
 *    type: button type ['button', 'submit', 'reset']
 */

const Btn = ({
    label,
    onClick,
    className,
    isRunning,
    type,
    disabled,
}) => {
  return (

    <button
      type={type}
      disabled={disabled}
      className={`btn lt-btn ${className} ${isRunning && 'running'}`}
      onClick={onClick}>
      {isRunning ? (
        <Spinner />
      ) : (
        <>{label}</>
      )}
    </button>
  )
}

Btn.defaultProps = {
  className: '',
  isRunning: false,
  type: 'button',
  disabled: false,
}

Btn.propTypes = {
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ]).isRequired,
  onClick: PropTypes.func,
  className: PropTypes.string,
  isRunning: PropTypes.bool,
  disabled: PropTypes.bool,
  type: PropTypes.oneOf(['button', 'submit', 'reset'])
}

const BlueBtn = props => <Btn {...props} className={`lt-btn-blue ${props.className}`} />;
const RedBtn = props => <Btn {...props} className={`lt-btn-red ${props.className}`} />;
const OutlineBtn = props => <Btn {...props} className={`lt-btn-outline ${props.className}`} />;
const HelpBtn = props => (
  <Btn
    {...props}
    className={`btn-help ${props.className}`}
    label={<img src={Help} alt="Help Btn" />} />
);
const CloseBtn = props => (
  <Btn
    {...props}
    className={`close ${props.className}`}
    label={<div className="close-icon change"><div className="bar1"></div><div className="bar2"></div><div className="bar3"></div></div>} />
);


export { Btn, BlueBtn, RedBtn, OutlineBtn, HelpBtn, CloseBtn };
