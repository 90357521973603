import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';

const LtDate = ({
  name,
  label,
  value,
  error,
  onChange,
  type,
  dateFormat,
  className
}, props) => {

  return (
    <div className={`lt-form-element ${className} ${error && 'error'}`}>
      <label htmlFor={name}>
        {label} <span className="input-error">{error}</span>
      </label>
      <DatePicker
        selected={moment(value).toDate()}
        onChange={date => onChange({target: {name, value: moment(date).format('YYYY-MM-DD')}})}
        id={name}
        name={name}
        dateFormat={dateFormat}
        className="select"
      />
    </div>
  );
};

LtDate.defaultProps = {
  type: 'text',
  className: '',
  dateFormat: 'MM/dd/yyyy'
}

LtDate.propTypes = {
  name: PropTypes.string,
  selected: PropTypes.array,
  className: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.string,
  type: PropTypes.string,
  dateFormat: PropTypes.string,
  onChange: PropTypes.func,
}

export { LtDate };
