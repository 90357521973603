import { useState, useReducer, useEffect, useCallback } from 'react'
import { validate } from '../utils/validators'



const useForm = (stateSchema, callback) => {
  const [{
    formState,
    status,
    error,
    isDisabled,
    isDirty
  }, setState] = useReducer(
    (state, action) => ({...state, ...action}),
    {
      formState: stateSchema,
      status: null,
      error: null,
      isDisabled: true,
      isDirty: false
    }
  )

  const [promise, setPromise] = useState()
  const isSuccess = status === 'complete'
  const isPending = status === 'pending'
  const isRejected = status === 'rejected'

  // Disable button in initial render.
  useEffect(() => {
    setState({isDisabled: true})
  }, [])

  useEffect(() => {
    if (isDirty) {
      setState({isDisabled: validateState()})
    }
  }, [formState, isDirty])

  const validateState = useCallback(() => {
    const hasErrorInState = Object.keys(formState).some(key => {
      return formState[key].error
    })

    return hasErrorInState
  }, [formState])

  const handleOnChange = (event) => {
    setState({isDirty: true})

    const name = event.target.name
    const value = event.target.value
    const validations = formState[name].validations ? formState[name].validations : null

    let error = ""

    if (validations !== null && typeof validations === 'object') {
      for (const [key, properties] of Object.entries(validations)) {

        let validationValue = value
        if(typeof value === "object") {
          validationValue = value.value
        }

        const result = validate[key](validationValue, properties.options)
        if(!result) {
          error = properties.message
          break
        }
      }
    }

    setState({formState: {...formState, [name]: {value, error, validations}}})

  }

  useEffect(() => {
    let isMounted = true
    if (isPending && promise) {
      promise.then(
        value => {
          if (isMounted) {
            setState({status: 'complete'})
          }
          return value
        },
        reason => {
          if (isMounted) {
            setState({status: 'rejected', error: reason})
          }
          return reason
        },
      )
    }
    return () => (isMounted = false)
  }, [isPending, promise])

  const handleSubmit = (event) => {
    event.preventDefault()
    let params = {}
    for(const [key, prop] of Object.entries(formState)){
      params[key] = prop.value
    }
    setState({status: 'pending'})
    const promise = callback(params)
    setPromise(promise)
    return promise
  }

  return {
    isPending,
    isRejected,
    isSuccess,
    isDisabled,
    handleSubmit,
    handleOnChange,
    formState,
    error,
  }
}


export { useForm }
