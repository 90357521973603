import React from 'react';
import PropTypes from 'prop-types';

/**
 * Props
 *    className: class to override error container
 *    message: Error Message
 */


const Error = ({message, className}) => {

  const error = JSON.parse(message);

  return (
    <div className={`error-message ${className}`}>
      {error.detail}
    </div>
  )
}


Error.defaultProps = {
  className: '',
}

Error.propTypes = {
  className: PropTypes.string,
  error: PropTypes.string
}

export { Error };
