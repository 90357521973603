import React from 'react';
import { ApolloProvider } from '@apollo/react-hooks';
import { NavProvider } from './nav-context';
import { client, ApiProvider } from './api';


export const AppProviders = ({children}) => {
    return (
      <NavProvider>
        <ApiProvider>
          <ApolloProvider client={client}>
              {children}
          </ApolloProvider>
        </ApiProvider>
      </NavProvider>
    )
};
