import React, { useState } from 'react'
import {  BlueBtn, OutlineBtn } from '../btns'
import { MealPlan } from './MealPlan'
import moment from 'moment'
import { AddMealPlan } from './AddMealPlan'
import { Loading } from '../../components/loading'

import { useQuery } from '@apollo/react-hooks'

import * as queries from '../../api/queries.js'

const AllMealPlans = () => {
  const [selectedPlanID, setSelectedPlanID] = useState(null)
  const { loading, error, data } = useQuery(queries.DB_STAFF_DASHBOARD)

  if(loading) {
    return <Loading />
  }

  // const plans = props.data.me.profile.family.plans;
  const plans = data.allMealPlans
  let allPlans = plans.map((plan, index) => (
    <div key={index} className={`mealplan ${plan.id === selectedPlanID ? 'active' : ''}`}>
      <OutlineBtn
        onClick={() => setSelectedPlanID(plan.id)}
        label={plan.family.lastName + " - " + moment(plan.planDate).format("MM/DD/YYYY")}
        type='submit'
        className='flex-1 small full-width'
      />
    </div>
  ))


  const selectedPlan = plans.find(plan => plan.id === selectedPlanID)

  return (
    <div className="mealplans">
      <div className="plans">
        <h2>Meal Plans</h2>
        <div className="plans-inner">
          { allPlans }
          <BlueBtn
            onClick={() => setSelectedPlanID(null)}
            label='Add Meal Plan'
            type='submit'
            className='flex-1 small full-width'
          />
        </div>
      </div>
      {selectedPlan ? (
        <MealPlan plan={selectedPlan} />
      ) : (
        <AddMealPlan data={data}/>
      )}
    </div>
  )
}

export {AllMealPlans}
