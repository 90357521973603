import React from 'react'
import { useMutation } from '@apollo/react-hooks'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Popup from 'reactjs-popup'
import { faTimes, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import Meal from './Meal.js'
import moment from 'moment'
import { RedBtn, OutlineBtn } from '../btns'

import * as queries from '../../api/queries.js'
import { TYPES } from '../../api/constants.js'


const MealPlanDay = (props) => {
  // Mutation to delete the current Template Day
  const [deleteMealDay, { loading }] = useMutation(queries.DELETE_MEALS, {
    variables: {
      mealPlanData: {
        id: props.plan.id,
        dayNumber: props.number
      }
    },
    refetchQueries: () => [{query: queries.DB_STAFF_DASHBOARD}]
  })

  const meals = props.meals.map(({ id, mealType, recipes }, index ) => (
    <Meal
      key={index}
      type={TYPES[mealType]}
      mealRecipes={recipes}
      day={props.number}
      mealPlanId={props.plan.id}
      mealId={id}
      dayId={null}/>
  ))

  const Modal = () => (
    <Popup className="modal--delete" trigger={<span><FontAwesomeIcon icon={faTrashAlt} /></span>} modal>
      {close => (
        <div className="modal">
          <div className="header">
            <h3>Delete Meal</h3>
            <FontAwesomeIcon icon={faTimes} onClick={close}/>
          </div>
          <div className="content">
            Are you sure that you want to delete <span className="popup__content--blue">Day {props.number}</span>?
          </div>
          <div className="buttons">
            <OutlineBtn
              onClick={close}
              label='Cancel'
              type='submit'
              className='small'
            />
            <RedBtn
              className='small'
              label='Delete'
              onClick={() => deleteMealDay()}
              loading={loading}
            />
          </div>
        </div>
      )}
    </Popup>
  )
  return (
    <div className="day">
      <div className="number">
        Day {props.number}
        <div className="date">
          {moment(props.plan.planDate).add(props.number -1, 'days').format('ddd MMM DD')}
        </div>
        <div className="title__buttons">
          <Modal />
        </div>
      </div>
      <div className="meals">
        {meals}
      </div>
    </div>
  )
}

export default MealPlanDay
