import React, { useState, useEffect } from 'react';
import { gql } from 'apollo-boost';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { HelpBtn, RedBtn, OutlineBtn } from '../btns';
import Popup from "reactjs-popup";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faTimes } from '@fortawesome/free-solid-svg-icons';
import Day from './Day.js';
import Meal from './Meal.js';
import Recipe from './Recipe.js';

import * as queries from '../../api/queries.js'


const Template = (props) => {

  const [selectedPlan, setSelectedPlan] = useState(null);

  // create Day component for each day in current Template Plan
  const templateDaysList = props.days.filter(function(day) {
    if (day.templatePlan.id == props.number) {
      return true; // skip
    }
    return false;
  }).map(({ id, dayNumber, templatePlan, mealSet }, index ) => (
    <Day key={id} id={id} number={dayNumber} plan={templatePlan} meals={mealSet} />
  ));

  // Mutation for creating a new Template Day
  const [createTemplateDay, { loading, error, data }] = useMutation(queries.CREATE_DAY, {
    variables: {
      templateDayData: {
        dayNumber: templateDaysList.length + 1,
        templatePlanId: props.number,
        meals: [
          {mealType: "B"},
          {mealType: "L"},
          {mealType: "D"}
        ]
      }
    },
    refetchQueries: result => [{query: queries.DB_TEMPLATES}],
  });

  // Mutation for deleting the current Template Plan
  const [deleteTemplatePlan, { templatePlanData }] = useMutation(queries.DELETE_TEMPLATE_PLAN, {
    variables: {
      templatePlanData: {
        id: props.number,
        name: props.name
      }
    },
    refetchQueries: result => [{query: queries.DB_TEMPLATES}],
  });
  const Modal = () => (
    <Popup className="modal--delete" trigger={<span><FontAwesomeIcon icon={faTrashAlt} /></span>} modal>
      {close => (
        <div className="modal">
          <div className="header">
          <h3>Delete Template</h3>
          <FontAwesomeIcon icon={faTimes} onClick={close}/>
          </div>
          <div className="content">
            Are you sure that you want to delete <span className="popup__content--blue">{props.name} - {templateDaysList.length} Day Plan</span>?
          </div>
          <div className="buttons">
            <OutlineBtn
              onClick={close}
              label='Cancel'
              type='submit'
              className='small'
            />
            <RedBtn
              className='small'
              label='Delete'
              onClick={() => deleteTemplatePlan()}
            />
          </div>
        </div>
      )}
    </Popup>
  );
  return (
    <div className="template-inner">
      <div className="template-title">
        {props.name} - {templateDaysList.length} Day Plan
        <div className="title__buttons">
          <Modal />
        </div>
      </div>
      <div className="template-description">{props.description}</div>
      {templateDaysList}
      <div className="add-day">
        <OutlineBtn
          onClick={() => createTemplateDay()}
          label='Add Day'
          type='submit'
          isRunning={loading}
          className=' small '
        />
      </div>
    </div>
  )
}

export default Template;
