import React from 'react'

const ShoppingListLabel = (data) => {
  switch (data.data) {
    case 'raw':
      return 'Raw'
    case 'quick':
      return 'Quick'
    default:
      return null
  }
}
export {ShoppingListLabel}
