import React, { useState, useContext } from 'react'
import { useApi } from '../contexts/api'
import { push as Menu } from 'react-burger-menu'
import { Link, NavLink } from 'react-router-dom'
import { NavContext } from '../contexts/nav-context'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome, faCopy, faFile, faUser, faSignOutAlt, faUserShield } from '@fortawesome/free-solid-svg-icons'
import { useQuery } from '@apollo/react-hooks'

import * as queries from '../api/queries.js'
import logo from '../../images/logo-white.png'
import blueLogo from '../../images/logo-h.png'
import mobileLogo from '../../images/logo-mobile.png'
import { AFTER_LOGIN_URL } from '../api/constants'

const TopBar = ({ open, setOpen }) => {
  return (
    <div className="topbar">
      <Link to={AFTER_LOGIN_URL}>
        <img className="logged-in-logo" src={blueLogo} alt="logo" />
        <img className="logged-in-mobile-logo" src={mobileLogo} alt="logo" />
      </Link>
      <div className={`menu-icon ${open ? 'change' : ''}`} onClick={() => setOpen(val => !val)} >
        <div className="bar1"></div>
        <div className="bar2"></div>
        <div className="bar3"></div>
      </div>
    </div>
  )
}

export const Nav = ({ children }) => {
  const ctx = useContext(NavContext)
  const [open, setOpen] = useState(false)
  const api = useApi()
  const token = api.getToken()
  const uid = api.getUserId()

  const { loading, error, data } = useQuery(queries.PROFILE_ID)

  const { hasPermission, isLoading: permissionsLoading } = api.useCheckPermission({uid, permission: 'nextlevel.view_staff_dashboard', })

  const staffNav = [
    (<NavLink key={1} onClick={() => setOpen(val => !val)} className="menu-item" activeClassName="selected" to="/plans">
      <FontAwesomeIcon icon={faCopy} />
      Templates
    </NavLink>),
    (<a key={3} href="/admin/" className="menu-item">
      <FontAwesomeIcon icon={faUserShield} />
      Admin
    </a>),
  ]

  const sideNav = (<Menu
    right
    noOverlay
    isOpen={open}
    onStateChange={state => setOpen(state.isOpen)}
    customBurgerIcon={ false }
    pageWrapId={ "page-wrap" }
    outerContainerId={ "outer-container" }
  >
    <img className="bm-logo" src={logo} alt="Next Level" />
    <NavLink onClick={() => setOpen(val => !val)} className="menu-item" activeClassName="selected" exact to="/dashboard">
      <FontAwesomeIcon icon={faHome} />
      Dashboard
    </NavLink>
    <NavLink onClick={() => setOpen(val => !val)} className="menu-item" activeClassName="selected" exact to="/recipes">
      <FontAwesomeIcon icon={faFile} />
      Recipes
    </NavLink>
    <NavLink
      onClick={() => setOpen(val => !val)}
      className="menu-item"
      activeClassName="selected"
      exact
      to={"/patient/" + (loading ? 0 : data == undefined ? 0 : parseInt(data.me.profile.id))}
    >
      <FontAwesomeIcon icon={faUser} />
      Profile
    </NavLink>
    {permissionsLoading || !hasPermission ? null : staffNav}
    <NavLink onClick={() => setOpen(val => !val)} className="menu-item logout" activeClassName="selected" to="/logout">
      <FontAwesomeIcon icon={faSignOutAlt} />
      Logout
    </NavLink>
  </Menu>)

  return (
    <div id="outer-container">
      {token && ctx.isNav ? sideNav : null}
      <main id="page-wrap">
        <div className="dashboard-container">
          {token && ctx.isNav ? <TopBar open={open} setOpen={setOpen} /> : null}
          {children}
        </div>
      </main>
    </div>
  )
}
