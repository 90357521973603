import React from 'react'
import { useApi } from '../../contexts/api'
import { useMutation } from '@apollo/react-hooks'
import Popup from "reactjs-popup"
import { RedBtn, OutlineBtn } from '../btns'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilePdf, faTrashAlt, faTimes } from '@fortawesome/free-solid-svg-icons'

import MealPlanDay from './MealPlanDay.js'
import * as queries from '../../api/queries.js'


const MealPlan = ({plan}) => {
  const api = useApi()

  const days = []
  let mealSet = []
  plan.mealSet.forEach((meal, index) => {
    mealSet.push(meal)
    if (index % 3 == 2) {
      const day = parseInt(index/3) + 1
      days.push({dayNumber: day, mealSet: mealSet})
      mealSet = []
    }
  })

  const listDays = days.map(({dayNumber, mealSet}) => (
    <MealPlanDay key={dayNumber} number={dayNumber} plan={plan} meals={mealSet} />
  ))

  // Mutation for creating a new Meal Day
  const [addMeals, { ...addMealsData }] = useMutation(queries.ADD_MEALS, {
    variables: {
      mealPlanData: {
        id: plan.id
      }
    },
    refetchQueries: () => [{query: queries.DB_STAFF_DASHBOARD}],
  })

  const [deleteMealPlan, { ...deleteData }] = useMutation(queries.DELETE_MEAL_PLAN, {
    variables: {
      mealPlanData: {
        id: plan.id
      }
    },
    refetchQueries: () => [{query: queries.DB_STAFF_DASHBOARD}],
  })

  const title = plan.family.lastName + ': ' + listDays.length + ' Day Plan'

  const load_pdf = () => {
    api.getMealPlan(plan.id, 'PDF')
  }
  const Modal = () => (
    <Popup className="modal--delete" trigger={<span><FontAwesomeIcon icon={faTrashAlt} /></span>} modal>
      {close => (
        <div className="modal">
          <div className="header">
            <h3>Delete Meal Plan</h3>
            <FontAwesomeIcon icon={faTimes} onClick={close}/>
          </div>
          <div className="content">
            Are you sure that you want to delete <span className="popup__content--blue">{title}</span>?
          </div>
          <div className="buttons">
            <OutlineBtn
              onClick={close}
              label='Cancel'
              type='submit'
              className='small'
            />
            <RedBtn
              className='small'
              label='Delete'
              onClick={() => deleteMealPlan()}
              isRunning={deleteData.loading}
            />
          </div>
        </div>
      )}
    </Popup>
  )

  return (
    <div className="template">
      <div className="template-inner">
        <div className="template-title">
          {title}
          <div className="title__buttons">
            <FontAwesomeIcon icon={faFilePdf} onClick={() => load_pdf()}/>
            <Modal />
          </div>
        </div>
        {listDays}
        <div className="add-day">
          <OutlineBtn
            onClick={() => addMeals()}
            label='Add Day'
            isRunning={addMealsData.loading}
            type='submit'
            className=' small '
          />
        </div>
      </div>
    </div>
  )
}

export {MealPlan}
