import React, { useState } from 'react'
import Rating from 'react-rating'
import { BlueBtn } from '../btns'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar } from '@fortawesome/free-solid-svg-icons'


const Review = ({ profileId, firstName, lastName, ...props }) => {
  const [rating, setRating] = useState(props.rating)
  const [changed, setChanged] = useState(false)
  const [comment, setComment] = useState(props.comment ? props.comment : '')

  const getReviewStyle = (editable) => {
    if (editable) {
      return 'my-review-border'
    } else {
      return 'review-border'
    }
  }

  return (
    <div className={`review ${getReviewStyle(props.editable)}`}>
      <div className="review-title">
        <h3>{firstName} {lastName[0]}.</h3>
        <Rating
          emptySymbol={<FontAwesomeIcon style={{ color: '#D3D6DB' }} icon={faStar} />}
          fullSymbol={<FontAwesomeIcon style={{ color: '#f4a80f' }} icon={faStar} />}
          initialRating={rating}
          placeholderSymbol={<FontAwesomeIcon style={{ color: 'gray' }} icon={faStar} />}
          onChange={(value) => {
            setRating(value)
            if (value != props.rating) {
              setChanged(true)
            }
          }}
          readonly={!props.editable}
        />
      </div>
      <div className="comment">
        <textarea
          className="text"
          type="text"
          value={comment}
          onChange={(e) => {
            setComment(e.target.value)
            if (rating) {
              setChanged(true)
            }
          }}
          placeholder={props.editable ? 'Add a comment...' : ''}
          readOnly={!props.editable}
        />
      </div>
      {props.editable && changed ? (
        <div className="update">
          <BlueBtn
            onClick={() => {
              props.createComment({ variables: {
                reviewData: {
                  profileId: profileId,
                  recipeId: props.recipeId,
                  rating: rating,
                  comment: comment
                }
              }})
              setChanged(false)
            }}
            label='Save'
            isRunning={props.loading}
            className='flex-1 small'
          />
        </div>
      ) : ''}
    </div>
  )
}
export default Review
