import React, { useState, useEffect, useContext } from 'react'
import moment from 'moment'
import { useQuery } from '@apollo/react-hooks'
import { Link } from 'react-router-dom'
import { useApi } from '../contexts/api'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { Redirect } from 'react-router-dom'

import * as queries from '../api/queries.js'
import { Loading } from '../components/loading'
import { LtSelect } from  '../components/forms'
import { NavContext } from '../contexts/nav-context'

const PatientLink = ({ user }) => {
  if (!user) {
    return null
  }
  const displayLogin = (login) => {
    if (!login) {
      return 'Never'
    } else {
      return moment(login).format('LLLL')
    }
  }
  return (
    <Link to={'/patient/' + user.profile.id}>
      <div className="container">
        <div className="user">
          {user.firstName ? user.firstName : user.username} {user.lastName}
        </div>
        <div className="details">
          Last Login: {displayLogin(user.lastLogin)}
        </div>
      </div>
    </Link>
  )
}

const StaffDashboard = () => {
  const [selectValue, setSelectValue] = useState({label: 'Select a Patient', value:''})
  const { loading, error, data } = useQuery(queries.DB_STAFF_DASHBOARD)
  if (loading) return <Loading />
  if (error) {
    console.log(error)
    return <p>Error. Try refreshing the page.</p>
  }

  const submittedRecipes = data.allRecipes.filter(r => !r.approved).map(r => (
    <li key={r.id}>
      <Link to={`/admin/nextlevel/recipe/${r.id}/change/`} target="_blank" className="link">
        {r.name}
      </Link>
    </li>
  ))

  let selectUsers = [{label: 'Select a Patient...', value: ''}]
  const users = !data ? null : data.allPatients.map(user => {
    selectUsers.push({
      label: <PatientLink user={user} />,
      value: `${user.firstName ? user.firstName : user.username} ${user.lastName}`
    })
    return (
      <li key={user.id}>
        <PatientLink user={user} />
      </li>
    )
  })

  return (
    <div className="staff-dashboard">
      <div className="full-width">
        <Link className="dash-link" to={`/admin/nextlevel/mealplan/`} target="_blank" >{data.allMealPlans.length + ' Meal Plans'}</Link>
        <Link className="dash-link" to={`/plans`} >{data.allTemplatePlans.length + ' Templates'}</Link>
        <Link className="dash-link" to="/admin/auth/user/" target="_blank">{data.allPatients.length + ' Patients'}</Link>
        <Link className="dash-link" to="/recipes" >{data.allRecipes.filter(r => r.approved).length + ' Recipes'}</Link>
      </div>
      <div className="column">
        <div className="list-wrapper">
          <h2>Patients <Link to="/admin/auth/user/add/" target="_blank"><FontAwesomeIcon icon={faPlus} /></Link></h2>
          <LtSelect className="select" options={selectUsers} value={selectValue} onChange={(e) => {
            setSelectValue(e.target.value)
          }}/>
          <ul className="list">
            {selectValue.value ? <PatientLink user={selectValue.value} /> : users}
          </ul>
        </div>
      </div>
      {submittedRecipes.length ? (
        <div className="column">
          <div className="list-wrapper">
            <h2>Submitted Recipes</h2>
            <ul className="list">
              {submittedRecipes}
            </ul>
          </div>
        </div>
      ) : ''}
    </div>
  )
}


export const Dashboard = () => {
  const api = useApi()
  const uid = api.getUserId()
  const { setIsNav } = useContext(NavContext)

  useEffect(() => {
    setIsNav(true)
  }, [])

  const { hasPermission, isLoading: permissionsLoading } = api.useCheckPermission({uid, permission: 'nextlevel.view_staff_dashboard', })

  const { loading, error, data } = useQuery(queries.PROFILE_ID)

  if (permissionsLoading || loading || uid == '') return <Loading/>
  if (error) return (<p>{error}</p>)

  if (hasPermission) {
    return (<StaffDashboard api={api} />)
  } else if (!data.me.profile.acceptedTerms){
    return (<Redirect exact to='/setup' />)
  } else {
    return (<Redirect exact to={`/family/${data.me.profile.family.id}`}/>)
  }
}
