import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'

import { WEEKDAYS, DATE_FORMAT } from '../../../api/constants.js'
import DateContents from './DateContents'
import { MutationIcon } from './mutationBtn'

import * as queries from '../../../api/queries.js'


const MonthMeals = ({ dayOffset, setDayOffset, selectedDate, data, loading, api, ...props }) => {
  const refetchQueries = [{
    query: queries.FAMILY_CALENDAR,
    variables: {
      familyId: props.familyId,
      startDate: moment(props.range[0]).format(DATE_FORMAT),
      endDate: moment(props.range[1]).format(DATE_FORMAT)
    }
  }, {
    query: queries.CUSTOM_MEAL_PLAN_JSON,
    variables: {
      familyId: props.familyId,
      startDate: moment(props.startDate).format(DATE_FORMAT),
      endDate: moment(props.endDate).format(DATE_FORMAT)
    }
  }]
  const [deleteDayMeals, deleteDayMealsData] = api.useMutation(queries.DELETE_DAY_MEALS, {
    refetchQueries: () => refetchQueries
  })
  const [addDayMeals, addDayMealsData] = api.useMutation(queries.ADD_DAY_MEALS, {
    refetchQueries: () => refetchQueries
  })
  const [updateMeal] = api.useMutation(queries.UPDATE_MEAL, {
    refetchQueries: () => refetchQueries
  })

  const dateRange = () => {
    const firstDay = moment(selectedDate).startOf('month').startOf('week')
    const lastDay = moment(selectedDate).endOf('month').endOf('week')
    return [firstDay, lastDay]
  }
  const dateTuple = dateRange()

  const numDaysToRender = () => {
    const daysInMonth = moment(selectedDate).endOf('month').date()
    const prepend = moment(selectedDate).startOf('month').day()
    const append = 6 - moment(selectedDate).endOf('month').day()
    return prepend + daysInMonth + append
  }
  const dayOffsets = [...Array(numDaysToRender()).keys()]

  const weekLabels = WEEKDAYS.map((value, index) => {
    return <div key={index} className='meals-week__header'>
      <div className='meals-week__header-content'>
        {value}
      </div>
    </div>
  })

  const week = dayOffsets.map((value, index) => {
    const calendarDate = moment(dateTuple[0]).add(index, 'd')
    const formattedDate = calendarDate.format(DATE_FORMAT)
    const previousDate = moment(calendarDate).add(-1, 'd').format(DATE_FORMAT)
    const isFaded = calendarDate.format('M') != selectedDate.format('M')
    const isSelected = calendarDate.format('MM-DD') == selectedDate.format('MM-DD')

    return <div
      key={index}
      className={`meals-calendar-day meals-calendar-day_clickable ${isFaded ? 'meals-calendar-day_faded' : ''} ${isSelected ? 'meals-calendar-day_selected' : ''}`}
    >
      <div className='meals-calendar-day__header'>
        <span
          className='meals-calendar-day__number'
          onClick={() => {
            const offset = moment(calendarDate).startOf('day').diff(moment().startOf('day'), 'days')
            setDayOffset(offset)
          }}
        >
          {calendarDate.format('D')}
        </span>
        {props.editMode ? (
          <MutationIcon
            formattedDate={formattedDate}
            previousDate={previousDate}
            data={data}
            familyId={props.familyId}
            addDayMeals={addDayMeals}
            addDayMealsData={addDayMealsData}
            deleteDayMeals={deleteDayMeals}
            deleteDayMealsData={deleteDayMealsData}
            className='meals-calendar-day__button'
          />
        ) : ''}
      </div>
      {loading ? '' : (
        <DateContents
          data={data}
          formattedDate={formattedDate}
          updateMeal={updateMeal}
          dayOffset={dayOffset}
          setDayOffset={setDayOffset}
          {...props}
        />
      )}
    </div>
  })

  return (<>
    <div className='header-date-step'>
      <button className='back' onClick={() => {
        const daysInLastMonth = moment().add(-1, 'M').endOf('month').date()
        setDayOffset(dayOffset - daysInLastMonth)
        props.setStartDate(moment(selectedDate).add(-1, 'M').startOf('month'))
        props.setEndDate(moment(selectedDate).add(-1, 'M').endOf('month'))
      }}><FontAwesomeIcon icon={faChevronLeft}/></button>
      <button className='back' onClick={() => {
        setDayOffset(0)
        props.setStartDate(moment().startOf('month'))
        props.setEndDate(moment().endOf('month'))
      }}>Today</button>
      <button className='forward' onClick={() => {
        const daysInMonth = moment().endOf('month').date()
        setDayOffset(dayOffset + daysInMonth)
        props.setStartDate(moment(selectedDate).add(1, 'M').startOf('month'))
        props.setEndDate(moment(selectedDate).add(1, 'M').endOf('month'))
      }}><FontAwesomeIcon icon={faChevronRight}/></button>
      <h2>{moment(selectedDate).format('MMMM YYYY')}</h2>
    </div>
    <div className='meals-week-labels'>
      {weekLabels}
    </div>
    <div className='meals-week'>
      {week}
    </div>
  </>
  )
}
export default MonthMeals
