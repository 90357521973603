import React, { useState, createContext } from 'react';


const NavContext = React.createContext();

const NavProvider = ({ children }) => {
  const [isNav, setIsNav] = useState(true)

  return (
    <NavContext.Provider value={{
      isNav,
      setIsNav,
    }}>
      {children}
    </NavContext.Provider>
  )
}

export { NavContext, NavProvider };
