import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select'


const ReactSelect = ({ onChange, name, ...props}) => {
  const patchedOnChange = (value) => {
    onChange({ target: {value, name}});
  }
  return <Select classNamePrefix="inner" onChange={patchedOnChange} name={name} {...props} />
}

const LtSelect = ({
  name,
  placeholder,
  options,
  label,
  value,
  error,
  onChange,
  type,
  className
}, props) => {
  return (
    <div className={`lt-form-element ${className} ${error && 'error'}`}>
      <label htmlFor={name}>
        {label} <span className="input-error">{error}</span>
      </label>
      <ReactSelect
        {...props}
        id={name}
        name={name}
        options={options}
        value={value}
        onChange={onChange}
        className="select"
        placeholder={placeholder}
      />
    </div>
  );
};

LtSelect.defaultProps = {
  type: 'text',
  className: '',
}

LtSelect.propTypes = {
  name: PropTypes.string,
  placeholder: PropTypes.string,
  options: PropTypes.array,
  className: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.string,
  type: PropTypes.string,
  onChange: PropTypes.func,
}

export { LtSelect };
