import React from 'react'
import { CSSTransition } from 'react-transition-group'
import { useApi } from '../contexts/api'
import { Route, Redirect, useParams, useHistory, useLocation,  } from 'react-router-dom'
import { Loading } from './loading'
import { Nav } from './nav'
import { useQuery } from '@apollo/react-hooks'

import * as queries from '../api/queries.js'


export const ProtectedRoute = ({ permission, objectType, component: Component, ...args }) => {
  const api = useApi()
  const uid = api.getUserId()

  let objectId = args.computedMatch.params.id

  const { isLoading, error, hasPermission } = api.useCheckPermission({ uid, permission, objectType, objectId })

  return (
      <Route
        {...args}
        render={props => {
          if (isLoading) {
            return <Loading />
          }

          if (!uid || (error && error.networkError && error.networkError.statusCode == 401)) {
            api.logout()
            return (
              <Redirect
                to={{
                  pathname: "/login",
                  state: { from: props.location }
                }}
              />
            )
          }
          if (error) {
            return <div>Error: {error.message}</div>
          }
          if (!hasPermission) {
            return <div>You do not have permission to view this page</div>
          }

          return (<Component {...props} />)
        }}
      />
  )
}

export const UnprotectedRoute = ({ component: Component, ...args }) => {
  return (
    <Route
      {...args}
      render={props => (
        <CSSTransition
          in={props.match != null}
          timeout={1000}
          classNames="page"
          unmountOnExit
        >
          <Component {...props} />
        </CSSTransition>
      )}
    />
  )
}
