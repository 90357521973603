import React from 'react'
import { Link } from 'react-router-dom'


const Recipe = (props) => {

  return (
    <span>
      <Link className="recipe" to={'/recipe/' + props.id} target="_blank">
        {props.name}
      </Link>
    </span>
  )
}

export default Recipe
