import React, { useState } from 'react'
import { BlueBtn } from '../components/btns'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { OutlineBtn } from '../components/btns'
import { Link, useParams } from 'react-router-dom'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/free-solid-svg-icons'

import * as queries from '../api/queries.js'

import { Allergens } from '../components/details/Allergens.js'
import { Loading } from '../components/loading'
import { ShoppingListLabel } from '../components/details/profile/ShoppingList'
import { FrequencyLabel } from '../components/details/profile/ShoppingFrequency'
import { BoolResult } from '../components/details/profile/BoolResult'
import profilePic from '../../images/avatar.jpg'

const Patient = () => {
  const { id } = useParams()

  const [firstName, setFirstName] = useState('Loading')
  const [lastName, setLastName] = useState('')
  const [username, setUsername] = useState('Loading')
  const [email, setEmail] = useState('Loading')
  const [familyLink, setFamilyLink] = useState("Loading")
  const [phoneNumber, setPhoneNumber] = useState('')
  const [street, setStreet] = useState('')
  const [city, setCity] = useState('')
  const [stateProvince, setStateProvince] = useState('')
  const [zipCode, setZipCode] = useState('')
  const [country, setCountry] = useState('')

  const [updateAllergens, updateAllergensData] = useMutation(queries.UPDATE_PROFILE_ALLERGENS, {
    variables: { profileId: id },
    refetchQueries: () => [{query: queries.USER, variables: { profileId: id }}]
  })
  const [createAllergen, createAllergenData] = useMutation(queries.CREATE_ALLERGEN, {
    refetchQueries: () => [{query: queries.USER, variables: { profileId: id }}]
  })
  const [deleteAllergens, deleteAllergensData] = useMutation(queries.DELETE_ALLERGENS, {
    refetchQueries: () => [{query: queries.USER, variables: { profileId: id }}]
  })
  const { loading, error, data } = useQuery(queries.USER, {
    variables: {
      profileId: parseInt(id),
    }
  })

  if (loading || data == undefined) {
    return (
      <div className="Main">
        <div className="profile-header_loading">
          <Loading />
        </div>
      </div>
    )
  }
  if (error) return <p>Error: {error.message}</p>

  let famName = null
  let famID = null

  if (data.user == null) {
    return (
      <div className="profile">
        <div>
          <h3 className="empty">{`User with ID of ${id} does not exist.`}</h3>
        </div>
      </div>
    )
  } else if (data.user.profile == null) {
    return (
      <div className="profile">
        <div>
          <h3 className="empty">{data.user.firstName ? data.user.firstName : data.user.username} does not have a profile</h3>
        </div>
      </div>
    )
  }

  if (firstName === 'Loading' || firstName != data.user.firstName) {

    setFirstName(data.user.firstName)
    setLastName(data.user.lastName)
    setUsername(data.user.username)
    setEmail(data.user.email ? data.user.email : 'None')
    setPhoneNumber(data.user.profile.phoneNumber)
    const address = data.user.profile.family != null ? (data.user.profile.family.address || null) : null
    setStreet(address ? address.street : '')
    setCity(address ? address.city + ',': '')
    setStateProvince(address ? address.stateProvince : '')
    setZipCode(address ? address.zipCode : '')
    setCountry(address ? address.country : '')
    if (data.user.profile.family != null) {
      famName = data.user.profile.family.lastName
      famID = data.user.profile.family.id

      setFamilyLink(
        (
          <BlueBtn
            onClick={() =>  window.location.href='/family/' + famID}
            label={famName + ' Family'}
            type='submit'
            className='flex-1 small full-width'
          />
        )
      )
    } else {
      setFamilyLink(
        (
          <div>
            <p>{data.user.firstName.length ? data.user.firstName : data.user.username} hasn&apos;t been assigned to a family</p>
            <a href={'/admin/nextlevel/profile/' + data.user.profile.id + '/change/'}>
              Add a family here
            </a>
          </div>
        )
      )
    }
  }

  const editLink = data.me.isStaff ? `/admin/nextlevel/profile/${data.user.profile.id}/change/` : '/setup'

  let profileData = data.user.profile

  return (
    <div className="Main">
      <a className="edit-allergens" href={editLink}>
        <FontAwesomeIcon icon={faEdit}/>
      </a>
      <div className="profile-header">
        <div className="profile-pic-wrapper">
          <img className="profile-header__image" src={profilePic} />
          <div className="contact">
            <h2>{firstName.length ? firstName : username} {lastName}</h2>
            <div className="email">{email}</div>
            <div className="phone">{phoneNumber}</div><br />
            <div className="family-members">
              <div className="member">
                {familyLink}
              </div>
            </div>
          </div>
        </div>
        <div>
          <h2>Address:</h2>
          {street}<br />
          {city} {stateProvince}<br />
          {zipCode}<br />
        </div>
        <div>
          <h2>Details:</h2>
          <div><span>Shopping Location:</span> {profileData.shoppingLocation}</div>
          <div><span>Frequency:</span> <FrequencyLabel data={profileData.shoppingFrequency}/></div>
          <div><span>Shopping List Style:</span> <ShoppingListLabel data={profileData.shoppingListStyle}/></div>
          {data.me.isStaff ? <div><span>Terms and Conditions:</span> <BoolResult data={profileData.acceptedTerms}/></div> : null}
        </div>
        <div></div>
      </div>
      <div className="profile">
        <div className="allergens-template">
          <Allergens
            profileId={id}
            data={data}
            deleteAllergens={deleteAllergens}
            deleteAllergensData={deleteAllergensData}
            allAllergens={data.user.profile.allergen}
            createAllergen={createAllergen}
            createAllergenData={createAllergenData}
            updateAllergens={updateAllergens}
            updateAllergensData={updateAllergensData}
          />
        </div>
      </div>
    </div>
  )
}

export {Patient}
