import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTimesCircle, faPlus } from '@fortawesome/free-solid-svg-icons'
import { LtSelect } from  '../forms'
import { OutlineBtn } from '../btns'

import { Loading } from '../loading'

const selectFraction = [
  {label:'Select Fraction', value:''},
  {label:<><sup>1</sup>&frasl;<sub>4</sub></>, value:'1/4'},
  {label:<><sup>1</sup>&frasl;<sub>3</sub></>, value:'1/3'},
  {label:<><sup>1</sup>&frasl;<sub>2</sub></>, value:'1/2'},
  {label:<><sup>2</sup>&frasl;<sub>3</sub></>, value:'2/3'},
  {label:<><sup>3</sup>&frasl;<sub>4</sub></>, value:'3/4'}
]

const EditItem = ({whole, setWhole, unit, setUnit, fraction, setFraction, name, setName, created}) => {
  const deleteMeal = () => {
    setWhole('')
    setUnit('')
    setFraction('')
    setName('')
  }
  if (!whole && !unit && !fraction && !name && !created) {
    return null
  }
  const getLabel = (type, currentItem) => {
    for (const entry of type) {
      if (currentItem == entry.value){
        return entry
      }
    }
  }
  return (
    <li>
      <span className="whole"><input value={whole} type="number" min="1" onChange={e => setWhole(e.target.value)}/></span>
      <LtSelect
        className="select-fraction"
        name="selectFraction"
        label="Select Fraction"
        value={getLabel(selectFraction, fraction)}
        options={selectFraction}
        onChange={e => setFraction(e.target.value.value)}
      />
      <span className="unit"><label>Unit:</label><input value={unit} onChange={e => setUnit(e.target.value)}/></span>
      <span className="name"><label>Name:</label><input value={name} onChange={e => setName(e.target.value)}/></span>
      <button className="icon" onClick={(e) => deleteMeal(e)}>
        <FontAwesomeIcon className="small" icon={faTimesCircle} />
      </button>
    </li>
  )
}

const ShoppingList = ({ data, loading }) => {

  if (loading) return <Loading />

  const shoppinglist = JSON.parse(data.shoppingList.items).categories.map((data, index) => {
    const items = data.ingredients.map((item, index) => {
      let portion = ''
      if (item.size[1]) {
        portion = (<><sup>{item.size[1][0]}</sup><span className="fraction">&frasl;</span><sub>{item.size[1][2]}</sub></>)
      }

      return (
        <li key={index}> {item.size[0]} {portion} {item.unit} {item.name}</li>
      )
    })
    return (
      <div className="category" key={index}>
        <h3>{data.name}</h3>
        <ul>
          {items}
        </ul>
      </div>
    )
  })

  return (
    <div className="shopping-list">
      <div className="shoppinglist-body">
        <div className="categories">
          {shoppinglist}
        </div>
      </div>
    </div>
  )
}

export default ShoppingList
