import React, { useContext, useState, useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import { useQuery, useMutation } from '@apollo/react-hooks'
import StepWizard from 'react-step-wizard'
import moment from 'moment'

import * as queries from '../api/queries.js'
import { LtInput } from '../components/forms/input'
import { LtSelect } from '../components/forms/select'
import { BlueBtn, OutlineBtn } from '../components/btns'
import { ShoppingListLabel } from '../components/details/profile/ShoppingList'
import { FrequencyLabel } from '../components/details/profile/ShoppingFrequency'
import { BoolResult } from '../components/details/profile/BoolResult'
import { Loading } from '../components'
import { useApi } from '../contexts/api'
import { AFTER_LOGIN_URL } from '../api/constants'
import { NavContext } from '../contexts/nav-context'


const Nav = (props) => {
  const dots = []
  for (let i = 1; i <= props.totalSteps; i += 1) {
    const stepLabel = () => {
      switch (i) {
        case 1:
          return 'Personal Info'
        case 2:
          return 'Shopping Habbits'
        case 3:
          return 'Transitional Program'
        case 4:
          return 'Terms & Conditions'
        case 5:
          return 'Review'
        default:
          return ''
      }
    }
    const isActive = props.currentStep === i
    const isCompleted = () => {
      if (i < props.currentStep) {
        return 'complete'
      }
    }
    dots.push((<span className={`dot-wrapper ${isCompleted()} ${isActive ? 'active' : ''}`} key={`step-${i}`}>
      <div className={`circle-loader ${isActive ? 'active' : ''}`}>
        <div className="step-number">{i}</div>
        <div className="checkmark draw"></div>
      </div>
      <span className="label">{stepLabel()}</span>
    </span>
    ))
  }

  return (
    <div className='title'>
      <div>Setup</div>
      <div className='nav'>{dots}</div>
    </div>
  )
}

const Stats = ({
  nextStep,
  previousStep,
  totalSteps,
  step,
  updateProfile,
  acceptedTerms,
  profileData,
  ...props
}) => {
  const handleClick = (event) => {
    updateProfile({
      variables: {
        profile: profileData
      },
      ignoreResults: true,
      onComplete: event()
    })
  }
  const navLabel = () => {
    switch (step) {
      case 4:
        return 'Finish'
      default:
        return 'Continue'
    }
  }
  const disable = () => {
    let inputProps = {}
    if ((step == 4) && (acceptedTerms == false)) {
      inputProps = { disabled: true }
    }
    return inputProps
  }
  return (
    <div className="button-nav">
      <div className="button-nav-inner">
        { step > 1 &&
            <OutlineBtn
              onClick={() => handleClick(previousStep)}
              label='Back'
              className='flex-1 small'
            />
        }
        { step < totalSteps &&
          <BlueBtn
            {...disable()}
            onClick={() => handleClick(nextStep)}
            label={navLabel()}
            className='flex-1 small'
          />
        }
        { step == 5 &&
          <BlueBtn
            onClick={() => handleClick(() => props.setIsFinished(true))}
            label='Go to Dashboard'
            className='flex-1 small'
          />
        }
      </div>
    </div>
  )
}

const Step1 = props => {
  const family = props.data.me.profile.family
  const address = family ? family.address : null
  const [firstName, setFirstName] = useState(props.data.me.firstName)
  const [lastName, setLastName] = useState(props.data.me.lastName)
  const [email, setEmail] = useState(props.data.me.email)
  const [phoneNumber, setPhoneNumber] = useState(props.data.me.profile.phoneNumber)
  const [familySize, setFamilySize] = useState(props.data.me.profile.family.size)
  const [street, setStreet] = useState(address ? address.street : '')
  const [city, setCity] = useState(address ? address.city : '')
  const [stateProvince, setStateProvince] = useState(address ? address.stateProvince : '')
  const [zipCode, setZipCode] = useState(address ? address.zipCode : '')
  const [country, setCountry] = useState(address ? address.country : '')
  return (
    <div className="step-wrapper">
      <div className="column fifty">
        <LtInput
          name="first-name"
          label="First Name"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
        />
      </div>
      <div className="column fifty">
        <LtInput
          name="last-name"
          label="Last Name"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
        />
      </div>
      <div className="full-width">
        <LtInput
          name="email"
          label="Email Address"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <LtInput
          name="street"
          label="Street"
          value={street}
          onChange={(e) => setStreet(e.target.value)}
        />
      </div>
      <div className="column fifty">
        <LtInput
          name="phone"
          label="Phone Number"
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)}
        />
      </div>
      <div className="column fifty">
        <LtInput
          name="familySize"
          label="FamilySize"
          type="number"
          min="1"
          value={familySize.toString()}
          onChange={(e) => setFamilySize(e.target.value)}
        />
      </div>
      <div className="column fifty">
        <LtInput
          name="city"
          label="City"
          value={city}
          onChange={(e) => setCity(e.target.value)}
        />
        <LtInput
          name="stateProvince"
          label="State/Province"
          value={stateProvince}
          onChange={(e) => setStateProvince(e.target.value)}
        />
      </div>
      <div className="column fifty">
        <LtInput
          name="zipCode"
          label="Zip Code"
          value={zipCode}
          onChange={(e) => setZipCode(e.target.value)}
        />
        <LtInput
          name="country"
          label="Country"
          value={country}
          onChange={(e) => setCountry(e.target.value)}
        />
      </div>

      <Stats
        step={1}
        profileData={{
          userId:props.data.me.id,
          firstName:firstName,
          lastName:lastName,
          email:email,
          phoneNumber:phoneNumber,
          familySize:familySize,
          street:street,
          city:city,
          stateProvince:stateProvince,
          zipCode:zipCode,
          country:country
        }} {...props}
      />
    </div>
  )
}

const Step2 = props => {
  const [shoppingLocation, setShoppingLocation] = useState(props.data.me.profile.shoppingLocation)
  const getLabel = (type, currentItem) => {
    for (const entry of type) {
      if (currentItem == entry.value){
        return entry
      }
    }
  }

  const selectFrequency = [
    {label: "Once per Week", value: "1x_week"},
    {label: "Twice per Month", value: "2x_month"},
    {label: "Once per Month", value: "1x_month"}
  ]
  const [frequency, setFrequency] = useState(getLabel(selectFrequency, props.data.me.profile.shoppingFrequency))

  const selectShoppingListStyle = [
    {label: "Raw", value: "raw"},
    {label: "Quick", value: "quick"},
  ]
  const [shoppingListStyle, setShoppingListStyle] = useState(getLabel(selectShoppingListStyle, props.data.me.profile.shoppingListStyle))

  return (
    <div>
      <LtInput
        name="shopping-location"
        label="Shopping Location"
        value={shoppingLocation}
        onChange={(e) => setShoppingLocation(e.target.value)}
      />
      <LtSelect
        name="shopping-frequency"
        label="Shopping Frequency"
        options={selectFrequency}
        value={frequency}
        onChange={(e) => setFrequency(e.target.value)}
      />
      <LtSelect
        name="shopping-style"
        label="Shopping Style"
        options={selectShoppingListStyle}
        value={shoppingListStyle}
        onChange={(e) => setShoppingListStyle(e.target.value)}
      />

      <Stats
        step={2}
        profileData={{
          userId:props.data.me.id,
          shoppingLocation:shoppingLocation,
          shoppingFrequency:frequency ? frequency.value : null,
          shoppingListStyle:shoppingListStyle ? shoppingListStyle.value : null
        }} {...props}
      />
    </div>
  )
}

const Step3 = props => {
  const handleChange = (event) => {
    props.setStartTransitionProgram(event.target.value  == "transitional-program")
  }

  return (
    <div>
      <div className="iagree transitional-program">
        <h2>Transitional Program</h2>
        <p>By clicking this button I agree to add (in addition to my food test allergens) the 3 month seasonal transitional meal plan template which excludes the most common allergies and replaces them with the most common replacements we find in each season to get the biggest and quickest change in my health.</p>
        <div className="lt-form-element radio" onChange={(event) => handleChange(event)}>
          <input type="radio" id="transitional-program" defaultChecked value="transitional-program" name="foot test" /><label className={props.startTransitionProgram ? 'true' : ''}  htmlFor="transitional-program">YES use both my food test and the transitional program</label>
          <input type="radio" id="food-test-only" value="food-test-only" name="foot test" /><label className={props.startTransitionProgram ? '' : 'true'}  htmlFor="food-test-only">No just use my food test only</label>
        </div>
      </div>
      <Stats
        step={3}
        profileData={{
          userId:props.data.me.id,
        }}
        {...props} />
    </div>
  )
}
const Step4 = props => {
  const [acceptedDisclaimer, setAcceptedDisclaimer] = useState(false)
  const [acceptedConfidentiality, setAcceptedConfidentiality] = useState(false)
  const isAccepted = () => {
    return (acceptedDisclaimer && acceptedConfidentiality)
  }
  return (
    <div>
      <div className="iagree">
        <h2>Medical Disclaimer</h2>
        <p>The law required that I, NEXT LEVEL Health Center, must inform you and your family and have you agree to the risks and benefits of following a “seasonal meal plan and lifestyle program.” There is always a risk and benefit in everything you do but I am required to have you consult a medical doctor who may or may not know what we are trying to do or have any idea of what the benefits are but you need to consult them before you begin. More than likely they will have no idea what this is and will recommend you don’t do it. Again, I would like to remind you that you are in control of your health not them. So you get to make a choice. I would not recommend this program if it wasn’t needed and used for 1000s of years to get you on the correct healing path to reaching your goals. </p>
        <p>You will find below the medical disclaimer.</p>
        <p>All information, product and material provided by NEXT LEVEL Health Center while educating on a ancient process of healing. If unsure about this ancient process speak with your medical doctor regarding the applicability of any opinions or recommendations with respect to your symptoms or medical condition. The instructions and advice presented by NEXT LEVEL Health Center are in no way intended as medical advice or as a substitute for medical counseling. The information should be used in conjunction with the guidance and care of your physician. </p>
        <p>Consult your physician before beginning this program as you would any health program, weight loss or weight maintenance program. Your physician should be aware of all medical conditions that you may have as well as the medications and supplements you are taking.
        Caution… If you are on diuretics or diabetes medication, have liver or gallbladder disease or take any medications, you should proceed only under a doctor’s supervision. As with any plan, the weight loss phases of this nutritional plan should not be used by patients on dialysis or by pregnant or nursing women.
        </p>
        <p><i>By clicking (I agree) …I {props.data.me.firstName} {props.data.me.lastName} agree to the following Confidentiality agreement and waive all liability from NEXT LEVEL Health Center.</i></p>
        <LtInput
          name="Medical Disclaimer"
          label="I Agree"
          className="checkbox"
          type="checkbox"
          checked={acceptedDisclaimer}
          onChange={(e) => setAcceptedDisclaimer(e.target.checked)}
        />
      </div>
      <div className="iagree">
        <h2>Confidentiality Agreement</h2>
        <strong>BACKGROUND</strong>
        <ol>
          <li>The User is currently under agreement with the Provider. This agreement is a mutual agreement with a promise that the Provider will release access to the purchased product and services while the User promises to pay the Provider for these products and services. </li>
        </ol>
        <strong>IN CONSIDERATION OF</strong> and as a condition of the product and service the User and the Provider
        <ol type="A">
          <li>All written, video and verbal information and material disclosed and released provided by the Provider to the User is under this agreement of confidential information regardless of whether such information was provided before or after the date of this agreement or how it was provided to the User. </li>
          <li>The User acknowledge that in any position the User may hold, in and as a result of the User’s will, or may, be making use of, acquiring or adding to information about certain matters and things which are confidential to the Provider and which information is the exclusive property of the Provider. </li>
          <li>Confidential Information means all data and information relating to the product and services of the Provider, including but not limited to the following:
            <ol type="a">
              <li>“Software” which includes all recipes, seasonal concepts, user name, password, allergies test results, concepts, ideas, data, information, programing sequences, programing software, customer services, data provided by software, product and services purchased, leased, licensed or received by the software of the Provider. </li>
              <li>“Intellectual Property” Which includes information relating to the Provider’s rights prior to any public disclosure of such information, including but not limiting to the nature of the proprietary rights, production data, technical and engineering data, technical concepts, test data and test results, simulation results, the status and details of research and development of product and services, and information regarding acquiring, protecting, enforcing and licensing proprietary rights (includes patents, copyrights and trade secrets):</li>
              <li>“Marketing and Development Information” which included marketing and development plans of the Provider, price and cost data, price and fee amounts, pricing and billing policies, quoting procedures, marketing techniques and methods of obtaining business, forecasts and forecast assumptions and volumes, and future plans and potential strategies of the Provider which have been or are being discussed:</li>
              <li>“Product Information” which includes all specifications for products of the Provider as well as work product resulting from or related to work or projects performed or to be performed for the Provider, of any type or form in any stage of actual or anticipated research and development;</li>
              <li>“Production Processes” which includes processes used in the creation, production, and manufacturing of the product and service of the Provider, including but not limited to, formulas, patterns, molds, models, methods, technique, specifications, process, procedures, equipment, devices, programs, and design:</li>
              <li>“Service Information” which includes all data and information relating to the service and products by the Provider, including but not limiting to. Plans, schedules, manpower, inspection, and training information:</li>
              <li>“Proprietary Computer Code” which includes all sets of statements, instructions or programs of the Provider, whether in human readable or machine readable form, that are expressed, fixed, embodied or stored in any manner an the a can be used directly or indirectly in a computer “computer program” any report format, design or drawing created or produced by such Computer Programs; and all documentation, design specifications and charts, and operating procedures which support the Computer Program;</li>
              <li>“Computer Technology” which includes all scientific and technical information or material of the Provider, pertaining to any machine, appliance or process, including but not limited to, specifications, proposals, models, designs, formulas, test results and reports, analyses, simulation results, tables of operating conditions, materials, components, industrial skills, operating and test procedures, shop practices, know-how and show-how</li>
            </ol>
          </li>
          <li>Confidential Information will not included the following information:
            <ol type="a">
              <li>Information generally known in the industry of the Provider</li>
              <li>Information rightly in the possession of the Provider prior to receiving the Confidential information form the Provider</li>
            </ol>
          </li>
          <li>Confidential Obligation
            <ol type="a">
              <li>Except as otherwise provided in this agreement, the User and the Provider must keep the confidential information confidential</li>
              <li>Except as otherwise provided in this agreement, the Confidential Information will remain the exclusive property of the Provider and will only be used by the User for the permitted purpose. The User will not use the Confidential Information for any purpose that might be directly or indirectly detrimental to the Provider or any associated affiliates or subsidiaries</li>
              <li>The obligations to ensure and protect the confidentiality of the confidential information imposed on the Provider in the agreement and any obligations to provider notice under this agreement will survive the expiration or termination, as the case may be, of this agreement and those obligations will last indefinitely</li>
            </ol>
          </li>
          <li>The User may disclose any of the confidential Information
            <ol type="a">
              <li>The user agrees to take all necessary steps to ensure that the terms of this agreement are not violated by such personnel: and </li>
              <li>The User agrees to be responsible for and indemnify the Provider for any breach of this agreement by its personnel</li>
              <li>To the extent required by law or by the request or requirement of any judicial, legislative, administrative or other governmental body. </li>
            </ol>
          </li>
          <li>Ownership and title
            <ol type="a">
              <li>The User acknowledges and agrees that all rights, title and interest in any Confidential Information will remain the exclusive property of the Provider. Accordingly, the User specifically agree and acknowledge that the User will have no interest in the confidential information, including, without limitation, no interest in know-how, copyright, trademarks or trade name, notwithstanding the fact that the User may have created or contributes to the creation of that confidential information</li>
              <li>The User does hereby waive any moral rights that the User may have with respect to the confidential information</li>
              <li>The confidential Information will not include anything developed or produced by the User during the term of this agreement, including but nor limited to intellectual property, process, design, development, creation, research, invention, know-how, trade name, trademark or copyright that:
                <ol type="i">
                  <li>Was developed without the use od any equipment, supplies, facility, or confidential information of the Provider</li>
                </ol>
              </li>
            </ol>
          </li>
          <li>Remedies
            <ol type="a">
              <li>The User agrees and acknowledges that the confidential information is of a proprietary and confidential nature and that any failure to maintain the confidential information in breach of this agreement cannot be reasonably or adequately compensated for in money damages and would cause irreparable injury to the Provider. Accordingly, the User agrees that the Provider is entitled to, in addition to all other rights and remedies available to it at law or in equity, an injunction restraining the User, any of its personnel, and any agents of the User, from directly or indirectly committing or engaging in any act restricted by this agreement in relation to the confidential information.</li>
            </ol>
          </li>
          <li>Canceling services
            <ol type="a">
              <li>The User agrees, upon request of the Provider, or in the event that the User choses to cancel the services provided by the Provider. The User agrees to pay the remaining service and a cancelation fee of a monthly program access and the Provider will cancel the User’s service </li>
              <li>Access to the service for the User will be terminated and al data will be deleted.</li>
              <li>This agreement will automatically terminate on the date that the User stops paying for service provided. </li>
            </ol>
          </li>
          <li>NOTICE
            <ol type="a">
              <li>In the event that the User is required in a civil, criminal or regulatory proceeding to disclose any part of the Confidential information, the User will give to the Provider prompt written notice of such request so the Provider may seek an appropriate remedy or alternatively to waive the User’s compliance with the provision of this agreement in regards to the request.</li>
              <li>The address for any notice to be delivered to any of the parties to this agreement are as follows
                <ol type="i">
                  <li>NEXT LEVEL Health Center</li>
                  <li>P.O.BOX</li>
                  <li>Rigby Idaho 83442</li>
                </ol>
              </li>
            </ol>
          </li>
          <li>Governing Law
            <ol type="a">
              <li>This agreement will be constructed in accordance with and governed by the laws of the State of Idaho</li>
            </ol>
          </li>
          <li>General Provision
            <ol type="a">
              <li>The clauses, paragraphs, and subparagraphs contained in this agreement are intended to be read and construed independently of each other. If any part of this agreement is held to be invalid, this invalidity will not affect the operation of any other part of this agreement</li>
              <li>The User is liable for all costs, expenses, and expenditures including, and without limitation, the complete legal cost incurred by the Provider in enforcing this agreement as a result of any default of this agreement by the User</li>
              <li>The Provider and the User acknowledge that this agreement is reasonable, valid and enforceable. However, if a court of competent jurisdiction finds any of the provisions of this agreement to be too broad to be enforceable, it is the intention of the Provider and the User that such provision reasonable and enforceable, bearing in mind that it is the intention of the User to give the Provider the broadest possible protection to maintain the confidentiality of the confidential information</li>
              <li>No failure or delay by the Provider in exercising any power, right or privilege provided in this agreement will operate as a waiver, nor will any single or partial exercise of such rights, powers or privileges preclude and further exercise of them or the exercise of any other right, power or privilege provided in this agreement</li>
              <li>This agreement will inure to the benefit of and be binding upon the respective heirs, executors, administrators, successors and assigns, as the case may be, of the Provider and the User.</li>
              <li>This agreement constitutes the entire agreement between the parties and there are no further items or provisions, either oral or otherwise</li>
            </ol>
          </li>
        </ol>
        <p>IN WITNESS WHEREOF the Provider and the User have dully affixed their signatures under hand and seal on this {moment().format("Do")} day of {moment().format("MMMM")}, {moment().format("YYYY")}.</p>
        <p>I {props.data.me.firstName} {props.data.me.lastName} fully agree to the terms of this confidentially agreement in its entirety.</p>
        <LtInput
          name="acceptedConfidentiality"
          label="I Agree"
          className="checkbox"
          type="checkbox"
          checked={acceptedConfidentiality}
          onChange={(e) => setAcceptedConfidentiality(e.target.checked)}
        />
      </div>
      <Stats
        step={4}
        profileData={{
          userId:props.data.me.id,
          acceptedTerms:isAccepted(),
        }}
        acceptedTerms={isAccepted()}
        {...props} />
    </div>
  )
}
const Step5 = props => {

  const me = props.data.me
  const family = props.data.me.profile.family
  const address = family ? family.address : null

  return (
    <div>
      <div className="summary">
        <h2>Your Profile is Setup</h2>
        <p>
          <strong>Name:</strong> {me.firstName} {me.lastName}<br />
          <strong>Email:</strong> {me.email}<br />
          <strong>Phone Number:</strong> {me.profile.phoneNumber}<br />
        </p>
        <p>
          <strong>Address:</strong><br />
          {address ? address.street : '(none)'}<br />
          {address ? address.city : '(none)'}, {address ? address.stateProvince : '(none)'}<br />
          {address ? address.zipCode : '(none)'}<br />
        </p>
        <p>
          <strong>Shopping Location:</strong> {me.profile.shoppingLocation}<br />
          <strong>Frequency:</strong> <FrequencyLabel data={me.profile.shoppingFrequency}/><br />
          <strong>Shopping List Style:</strong> <ShoppingListLabel data={me.profile.shoppingListStyle}/><br />
        </p>
        <p>
          <strong>Terms and Conditions:</strong> <BoolResult data={true}/><br />
        </p>

      </div>
      <Stats
        noReturn
        setIsFinished={props.setIsFinished}
        profileData={{
          userId:me.id,
          startTransitionProgram:props.startTransitionProgram,
        }}
        step={5}
        {...props} />
    </div>
  )
}

const Setup = () => {
  const api = useApi()
  const uid = api.getUserId()
  const [startTransitionProgram, setStartTransitionProgram] = useState(true)
  const [isFinished, setIsFinished] = useState(false)
  const { hasPermission: isStaff, isLoading: permissionsLoading } = api.useCheckPermission({uid, permission: 'nextlevel.view_staff_dashboard', })
  const { loading: profileLoading, error, data } = useQuery(queries.SETUP_PROFILE)
  const [updateProfile] = useMutation(queries.EDIT_PROFILE)
  const { setIsNav } = useContext(NavContext)

  useEffect(() => {
    setIsNav(false)
  }, [])

  if (permissionsLoading || profileLoading || data == undefined){
    return (<Loading/>)
  } else if (isStaff) {
    return (<p>404 not found</p>)
  } else if (isFinished) {
    return (<Redirect to={AFTER_LOGIN_URL} />)
  } else {
    return (
      <div className="setup-wizard">
        <StepWizard className="steps-wizard" nav={<Nav />}>
          <Step1 updateProfile={updateProfile} data={data} />
          <Step2 updateProfile={updateProfile} data={data} />
          <Step3 startTransitionProgram={startTransitionProgram} setStartTransitionProgram={setStartTransitionProgram} updateProfile={updateProfile} data={data} />
          <Step4 updateProfile={updateProfile} data={data} />
          <Step5 startTransitionProgram={startTransitionProgram} setIsFinished={setIsFinished} updateProfile={updateProfile} data={data} />
        </StepWizard>
      </div>
    )
  }
}

export {Setup}
