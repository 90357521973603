import React, { useState, useEffect } from 'react';
import { gql } from 'apollo-boost';
import { LtInput, LtSelect, LtDate } from  '../forms';

import moment from 'moment';
import Autosuggest from 'react-autosuggest';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { useForm } from '../../hooks/form';
import { HelpBtn, RedBtn, BlueBtn, OutlineBtn } from '../btns';


import * as queries from '../../api/queries.js';


const AddMealPlan = (props) => {
  const fams = [];
  props.data.allFamilies.forEach(family => {
    fams.push(family);
  })

  const [meals, setMeals] = useState([]);
  const stateSchema = {
      mealPlanFamily: {
          value: '',
          error: '',
          validations: {
              isRequired: { message: 'Select a Family'},
          }
      },
      mealPlanDate: {
          value: moment().format('YYYY-MM-DD'),
          error: '',
          validations: {
              isRequired: { message: 'Meal Plan Start Date'},
          }
      },
      mealPlanTemplate: {
          value: '',
          error: '',
          validations: {
              isRequired: { message: 'Select a Template'},
          }
      }
  }

  const {
      isSuccess,
      isPending,
      isRejected,
      isDisabled,
      handleSubmit,
      handleOnChange,
      formState,
  } = useForm(stateSchema, () => {})

  const [createMealPlan, { loading, mError }] = useMutation(queries.CREATE_MEAL_PLAN, {
    variables: {
      mealPlanData: {
        planDate: formState.mealPlanDate.value,
        meals: meals
      },
      familyData: {
        id: formState.mealPlanFamily.value.value
      },
    },
    refetchQueries: result => [{query: queries.DB_STAFF_DASHBOARD}],
  });

  const updateList = (plan) => {

    if (!plan) {
      setMeals([]);
      return;
    } else {
      handleOnChange(plan);
      const planId = plan.target.value.value;
      let mySelectedPlan = props.data.allTemplatePlans.find(tp => tp.id === planId);

      const mealSet = [];
      mySelectedPlan.templatedaySet.forEach(day => {
        day.mealSet.forEach(meal => {
          const recipes = [];
          meal.recipes.forEach(recipe => {
            recipes.push({id: recipe.id});
          })
          mealSet.push({mealType: meal.mealType, recipes: recipes});
        })
      });

      setMeals(mealSet);
    }

  }

  let selectFamilies = [];
  props.data.allFamilies.forEach(family => {
    selectFamilies.push({ label:family.lastName, value:family.id });
  })

  let selectPlan = [];
  props.data.allTemplatePlans.forEach(plan => {
    selectPlan.push({ label:plan.name, value:plan.id });
  })

  return (
    <div className="template">
      <h2>Add Meal Plan </h2>

      <form className="create-mealplan" autoComplete="off">
        <LtSelect
          name="mealPlanFamily"
          label="Meal Plan Family"
          options={selectFamilies}
          {...formState.mealPlanFamily}
          onChange={handleOnChange}
         />
        <LtDate
          name="mealPlanDate"
          label="Meal Plan Date"
          {...formState.mealPlanDate}
          onChange={handleOnChange}
        />
        <LtSelect
          name="mealPlanTemplate"
          label="Meal Template"
          options={selectPlan}
          {...formState.mealPlanTemplate}
          onChange={(input) => updateList(input)}
        />
        <BlueBtn
          onClick={createMealPlan}
          label='Save'
          isRunning={loading}
          className='flex-1'
          disabled={isDisabled}
        />
      </form>
    </div>
  )
}

export {AddMealPlan};
