import React, { createContext, useContext } from 'react'
import { useCookies } from 'react-cookie'
import { API } from '../api'


const ApiContext = createContext()
const api = new API()
const client = api.client // ApolloProvider needs a reference to the ApolloClient


const ApiProvider = (props) => {
  return <ApiContext.Provider value={api} {...props} />
}


const useApi = () => {
  const context = useContext(ApiContext)
  if (context === undefined) {
    throw new Error(`useApi must be used within a ApiProvider`)
  }

  const [cookies, setCookie, removeCookie] = useCookies()
  api.init(cookies, setCookie, removeCookie)
  return context
}


export { client, ApiProvider, useApi }
