import React from 'react';
import PropTypes from 'prop-types';


const TextArea = ({
  name,
  label,
  value,
  error,
  onChange,
  placeholder,
  type,
  className
}) => {
  return (
    <div className={`lt-form-element ${className} ${error && 'error'}`}>
      <label htmlFor={name}>
        {label} <span className="input-error">{error}</span>
      </label>
      <textarea
        id={name}
        name={name}
        type={type}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
      />

    </div>
  );
};

TextArea.defaultProps = {
  type: 'text',
  className: '',
}

TextArea.propTypes = {
  name: PropTypes.string,
  className: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  error: PropTypes.string,
  type: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
}

export { TextArea };
