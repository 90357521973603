import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import moment from 'moment';
import { HelpBtn, BlueBtn, OutlineBtn } from '../btns'
import { useQuery, useMutation } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import Day from './Day.js';
import Meal from './Meal.js';
import Recipe from './Recipe.js';
import Template from './Template.js';

import * as queries from '../../api/queries.js';

const types = {
  B: 'Breakfast',
  L: 'Lunch',
  D: 'Dinner'
}

const MealTemplate = ({plan}) => {

    const { loading, error, data } = useQuery(queries.DB_TEMPLATES);

    if(loading) {
      return "Loading";
    }

    return (
      <>
      {plan && (
        <div className="template">
          <Template number={plan.id} name={plan.name} description={plan.description} days={data.allTemplateDays} />
        </div>
      )
      }
      </>
    )
}

export {MealTemplate};
