import React from 'react'
import * as queries from '../../api/queries.js'
import { OutlineBtn, RedBtn } from '../btns'
import moment from 'moment'
import { LtSelect } from  '../forms'
import Rating from 'react-rating'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar } from '@fortawesome/free-solid-svg-icons'

import { useApi } from '../../contexts/api'
import { SPRING, SUMMER, FALL, WINTER } from '../dataImgs'

const seasonImgs = {
  'Spring': SPRING,
  'Summer': SUMMER,
  'Fall': FALL,
  'Winter': WINTER
}

// Possible mealtypes
const types = {
  Breakfast: 'B',
  Lunch: 'L',
  Dinner: 'D'
}

// Recipes to be displayed on the pop-up
const AllMeals = (props) => {
  const api = useApi()
  const { loading, error, data } = api.useQuery(queries.NAMED_MEALS, {
    variables: {
      mealType: types[props.type],
      mealDate: moment(props.date).format('YYYY-MM-DD')
    }
  })

  if (loading) return <p>Loading...</p>
  if (error) return <p>Error</p>

  const mealsList = data.namedMeals.map(({ id, name, recipes }) => {
    const recipesList = recipes.map((value, index) => {
      let myRating = null
      let color = '#f4a80f'
      value.recipeReviews.map(({ profile, rating }) => {
        if (profile.user.id === data.me.id) {
          myRating = rating
          color = '#20577D'
        }
      })
      return (
        <li key={index} className="meal-recipes">
          {value.name} &nbsp;
          {value.rating ? (
            <Rating
              emptySymbol={<FontAwesomeIcon style={{ color: '#D3D6DB' }} icon={faStar} />}
              fullSymbol={<FontAwesomeIcon style={{ color: color }} icon={faStar} />}
              initialRating={myRating ? myRating : value.rating}
              fractions={10}
              readonly={true}
            />
          ) : null}
        </li>
      )
    })
    const mealData = (
      <div>
        <div className="meal-header">{name}</div>
        <ul>
          {recipesList}
        </ul>
      </div>
    )
    return (
      <li key={id}>
        <OutlineBtn
          className='flex-1 full-width small'
          label={mealData}
          onClick={() => {
            props.updateMeal({
              variables: {
                mealId: props.mealId,
                copyId: id
              }
            })
            props.closePopup()
          }}
        />
      </li>
    )
  })

  const seasons = data.currentSeasons.map(({name}, index) => (
    <img key={index} className="season" src={seasonImgs[name]} />
  ))
  let selectMeals = []
  data.namedMeals.forEach(meal => {
    selectMeals.push({ label:meal.name, value:meal })
  })

  const handleClick = (mealId, copyId) => {
    props.updateMeal({
      variables: {
        mealId: mealId,
        copyId: copyId
      }
    })
    props.closePopup()
  }
  return (
    <div className="recipe">
      {seasons}
      <LtSelect
        name="selectMeals"
        label="Select Meals"
        placeholder="Select Meal"
        options={selectMeals}
        onChange={(e) => handleClick(props.mealId, e.target.value.value.id)}
      />
      <ul className="recipe-list">
        <li>
          <RedBtn
            className='flex-1 full-width small'
            label='Fasting'
            onClick={() => handleClick(props.mealId, null)}
          />
        </li>
        {mealsList}
      </ul>
    </div>
  )
}

export default AllMeals
