import React from 'react'
import moment from 'moment'
import { OutlineBtn, RedBtn } from '../../btns'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faTrashAlt, faTimes } from '@fortawesome/free-solid-svg-icons'
import Modal from '../../modal'
import Popup from 'reactjs-popup'


const MutationBtn = ({ formattedDate, previousDate, data, ...props }) => {
  if (data[formattedDate] != undefined) {
    const variables = {
      variables: {
        mealPlanId: data[formattedDate][0].mealPlan.id,
        mealDate: formattedDate
      }
    }
    return (
      <div className={props.className}>
        <Popup
          className="modal--delete"
          trigger={
            <span className={props.iconStyle}>
              <FontAwesomeIcon icon={faTrashAlt} />
            </span>
          }
          modal
        >
          {close => (
            <div className="modal">
              <div className="header">
                <h3>{props.header}</h3>
                <FontAwesomeIcon icon={faTimes} onClick={close}/>
              </div>
              <div className="content">
                {props.message}
              </div>
              <div className="buttons">
                <OutlineBtn
                  onClick={close}
                  label='Cancel'
                  type='submit'
                  className='small'
                />
                <RedBtn
                  className='small'
                  label='Delete'
                  onClick={() => {
                    props.delete(variables)
                    close()
                  }}
                  loading={props.loading}
                />
              </div>
            </div>
          )}
        </Popup>
      </div>
    )
  } else if (data[previousDate]) {
    return (
      <button
        className={props.className}
        disabled={props.addDayMealsData.loading}
        onClick={() => {
          props.addDayMeals({
            variables: {
              mealsData: {
                mealPlanId: data[previousDate][0].mealPlan.id,
                familyId: props.familyId,
                givenDate: formattedDate
              }
            }
          })
        }}
      ><FontAwesomeIcon icon={faPlus} /></button>
    )
  } else {
    return (
      <button
        className={props.className}
        disabled={props.addDayMealsData.loading}
        onClick={() => {
          props.addDayMeals({
            variables: {
              mealsData: {
                familyId: props.familyId,
                givenDate: formattedDate
              }
            }
          })
        }}
      >
        <FontAwesomeIcon icon={faPlus} />
      </button>
    )
  }
}


const MutationIcon = ({ formattedDate, previousDate, data, ...props }) => {
  if (data[formattedDate] != undefined) {
    const variables = {
      variables: {
        mealPlanId: data[formattedDate][0].mealPlan.id,
        mealDate: formattedDate
      }
    }
    return (
      <div className={props.className}>
        <Modal
          header='Delete Meals'
          message={<>Are you sure that you want to delete the meals for <span className="popup__content--blue">{moment(formattedDate).format('MMMM DD')}</span>?</>}
          loading={props.deleteDayMealsData.loading}
          data={variables}
          delete={props.deleteDayMeals}
        />
      </div>
    )
  } else if (data[previousDate]) {
    return (
      <button
        className={props.className}
        disabled={props.addDayMealsData.loading}
        onClick={() => {
          props.addDayMeals({
            variables: {
              mealsData: {
                mealPlanId: data[previousDate][0].mealPlan.id,
                familyId: props.familyId,
                givenDate: formattedDate
              }
            }
          })
        }}
      ><FontAwesomeIcon icon={faPlus} /></button>
    )
  } else {
    return (
      <button
        className={props.className}
        disabled={props.addDayMealsData.loading}
        onClick={() => {
          props.addDayMeals({
            variables: {
              mealsData: {
                familyId: props.familyId,
                givenDate: formattedDate
              }
            }
          })
        }}
      >
        <FontAwesomeIcon icon={faPlus} />
      </button>
    )
  }
}
export { MutationIcon, MutationBtn }
