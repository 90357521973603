import React from 'react'
import { Link } from 'react-router-dom'

const FamilyMembers = (props) => {
  const members = props.profiles.map((profile, index) => (
    <div className="member" key={index}>
      <Link className="link" to={'/patient/' + profile.id}>
        <span>{profile.user.firstName ? profile.user.firstName : profile.user.username} {profile.user.lastName}</span>
      </Link>
    </div>
  )
  )

  return (
    <div>
      <div className="family-members">
        {members ? members : "No family members"}
      </div>
    </div>
  )
}

export default FamilyMembers
