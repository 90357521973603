import validator from 'validator'


export const validate = {
  isRequired: (value) => {
    return validator.isLength(value, {min:1})
  },

  isLength: (value, options) => {
    return validator.isLength(value, {...options})
  },

  isEmail: (value, options) =>  {
    return validator.isEmail(value, {...options})
  },

  isUnique: (value, options) => {
    return !validator.isIn(value, options.values)
  },

  isEqual: (value, options) => {
    return validator.equals(value, options.value)
  },

  isPositive: (value) => {
    return value > 0 || value == '' || value == null || value == undefined
  },

  notNegative: (value) => {
    return value >= 0 || value == '' || value == null || value == undefined
  },
}
