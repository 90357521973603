import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt, faTimes } from '@fortawesome/free-solid-svg-icons'
import Popup from 'reactjs-popup'

import { RedBtn, OutlineBtn } from './btns'

const Modal = (props) => {
  return (
    <Popup className="modal--delete" trigger={<span><FontAwesomeIcon icon={faTrashAlt} /></span>} modal>
      {close => (
        <div className="modal">
          <div className="header">
            <h3>{props.header}</h3>
            <FontAwesomeIcon icon={faTimes} onClick={close}/>
          </div>
          <div className="content">
            {props.message}
          </div>
          <div className="buttons">
            <OutlineBtn
              onClick={close}
              label='Cancel'
              type='submit'
              className='small'
            />
            <RedBtn
              className='small'
              label='Delete'
              onClick={() => {
                props.delete(props.data)
                close()
              }}
              loading={props.loading}
            />
          </div>
        </div>
      )}
    </Popup>
  )
}
export default Modal
