import React from 'react'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'

import Meal from '../../plan/Meal'
import { TYPES, DATE_FORMAT, DAY } from '../../../api/constants.js'


const DateContents = ({ data, formattedDate, ...props }) => {
  let contents = null
  const tomorrow = moment(formattedDate).add(1, 'd').format(DATE_FORMAT)
  const prep = data[tomorrow] ? data[tomorrow].some(m => !m.leftovers && m.recipes.some(r => r.overnightPrep)) : false
  if (data[formattedDate] != undefined) {
    contents = data[formattedDate].map((value, index) => {
      return (
        <div key={index} className='meals-calendar-day__meal'>
          <Meal
            name={value.name}
            type={TYPES[value.mealType]}
            mealRecipes={value.recipes}
            leftovers={value.leftovers}
            day={null}
            date={moment(formattedDate)}
            mealPlanId={value.mealPlan.id}
            mealId={value.id}
            dayId={null}
            {...props}
          />
        </div>
      )
    })
  }
  return (
    <div>
      {contents}
      {prep ? (
        <div
          onClick={() => {
            const offset = moment(formattedDate).startOf('day').diff(moment().startOf('day'), 'days') + 1
            props.setStartDate(moment().add(offset, 'd'))
            props.setEndDate(moment().add(offset, 'd'))
            props.setCalendarView(DAY)
            props.setDayOffset(offset)
          }}
          className="alert alert-warning small clickable">
          <FontAwesomeIcon className="small exclamation" icon={faExclamationTriangle} />
          Start Overnight Prep
        </div>
      ) : ''}
    </div>
  )
}
export default DateContents
