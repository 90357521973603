import React, { useState } from 'react';
import { gql } from 'apollo-boost';
import { LtInput, TextArea } from  '../forms';
import moment from 'moment';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { useForm } from '../../hooks/form';
import { HelpBtn, RedBtn, BlueBtn, OutlineBtn } from '../btns';


import * as queries from '../../api/queries.js'


const AddTemplate = (props) => {
  const stateSchema = {
      templateName: {
          value: '',
          error: '',
          validations: {
              isRequired: { message: 'Name is Required'},
          }
      },
      templateDesc: {
          value: '',
          error: '',
          validations: {
              isRequired: { message: 'Description is Required'},
          }
      }
  }

  const {
      isSuccess,
      isPending,
      isRejected,
      isDisabled,
      handleSubmit,
      handleOnChange,
      formState,
  } = useForm(stateSchema, () => {})

  const [createTemplatePlan, { mError }] = useMutation(queries.CREATE_TEMPLATE, {
    variables: {
      templatePlanData: {
        name: formState.templateName.value,
        description: formState.templateDesc.value
      }
    },
    refetchQueries: result => [{query: queries.DB_TEMPLATES}],
  });
  if (mError) {
    console.log('error: ', mError)
  }

  const updateList = () => {
    createTemplatePlan();
  }

  return (
    <div className="template">
      <h2>Add Template </h2>

      <form className="create-template">
        <LtInput
          name="templateName"
          label="Name"
          {...formState.templateName}
          onChange={handleOnChange}
        />
      <TextArea
          name="templateDesc"
          label="Description"
          {...formState.templateDesc}
          onChange={handleOnChange}
        />
        <BlueBtn
          onClick={createTemplatePlan}
          label='Save'
          type='submit'
          className='flex-1'
          disabled={isDisabled}
        />
      </form>
    </div>
  )
}

export {AddTemplate};
