import { useEffect, useState } from 'react'
import ApolloClient from 'apollo-boost'
import {
  useQuery as useGraphQLQuery,
  useLazyQuery as useGraphQLQueryLazy,
  useMutation as useGraphQLMutation
} from '@apollo/react-hooks'
import jwtDecode from 'jwt-decode'

import { COOKIE_NAME, AFTER_LOGIN_URL } from './constants'
import { NetworkError } from './errors'

class API {
  constructor() {
    this.client = new ApolloClient({
      request: async operation => {
        const token = this.getToken()
        operation.setContext({headers: { authorization: token ? `Bearer ${token}` : ''}})
      },
      uri: '/graphql',
    })
  }

  headers() {
    const headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    }
    const token = this.getToken()
    if (token) {
      headers.Authorization = `Bearer ${token}`
    }
    return headers
  }

  init(cookies, setCookie, removeCookie) {
    this.cookies = cookies
    this.setCookie = setCookie
    this.removeCookie = removeCookie
  }

  useQuery(query, options) {
    const { error, ...args } = useGraphQLQuery(query, options)

    useEffect(() => {
      // NOTE: For now any authorization or authentication failures should log the user out
      if (error && error.networkError && [401, 403].includes(error.networkError.statusCode)) {
        this.logout()
      }
    })
    return { error, ...args }
  }

  useLazyQuery(query, options) {
    const [runQuery, { error, ...args }] = useGraphQLQueryLazy(query, options)
    useEffect(() => {
      // NOTE: For now any authorization or authentication failures should log the user out
      if (error && error.networkError && [401, 403].includes(error.networkError.statusCode)) {
        this.logout()
      }
    })

    return [runQuery, { error, ...args }]
  }

  useMutation(query, options) {
    const [runQuery, { error, ...args }] = useGraphQLMutation(query, options)
    useEffect(() => {
      // NOTE: For now any authorization or authentication failures should log the user out
      if (error && error.networkError && [401, 403].includes(error.networkError.statusCode)) {
        this.logout()
      }
    })

    return [runQuery, { error, ...args }]
  }

  getToken() {
    return this.cookies[COOKIE_NAME]
  }

  setToken(data) {
    this.setCookie(COOKIE_NAME, data.access)
    return data.access
  }

  getUserId() {
    const token = this.getToken()
    let claims = token ? jwtDecode(token) : {}
    return claims.sub || ''
  }

  request(endpoint, {body, ...customConfig} = {}) {
    const headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    }
    const token = this.getToken()
    if (token) {
      headers.Authorization = `Bearer ${token}`
    }
    const config = {
      method: body ? 'POST' : 'GET',
      ...customConfig,
      headers: {
        ...headers,
        ...customConfig.headers,
      }
    }
    if (body) {
      config.body = JSON.stringify(body)
    }
    return window
      .fetch(`${endpoint}`, config)
      .then(async r => {
        const data = await r.json()
        if (r.ok) {
          return data
        }
        else {
          throw new NetworkError(r.status, JSON.stringify(data))
        }
      })
  }

  logout() {
    this.removeCookie(COOKIE_NAME, { path: '/' })
  }

  login({username, password}) {
    return this.request(
      '/api/token', {
        body: {username, password}
      }).then(data =>  {
      this.setToken(data)
      return data
    })
  }

  register({username, email, firstName, lastName, password}) {
    return this.request(
      '/api/register', {
        body: {username, email, first_name: firstName, last_name: lastName, password}
      }
    ).then(() => {
      this.login({username, password})
    })
  }

  getShoppingList(shoppingList) {
    const headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'responseType': 'blob',
    }
    const token = this.getToken()
    if (token) {
      headers.Authorization = `Bearer ${token}`
    }
    const config = {
      method: 'POST',
      headers: {
        ...headers,
      },
      body: JSON.stringify({
        plans: [{
          sections: [
            shoppingList
          ]
        }]
      })
    }
    return window
      .fetch(`/pdf/shoppinglist?format=PDF`, config)
      .then(response => response.blob())
      .then(blob => {
        const fileURL = URL.createObjectURL(blob)
        window.open(fileURL)
      })
      .catch(error => {
        console.log(error)
      })
  }

  getMealPlanShoppingList(id) {
    const headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'responseType': 'blob',
    }
    const token = this.getToken()
    if (token) {
      headers.Authorization = `Bearer ${token}`
    }
    const config = {
      method: 'GET',
      headers: {
        ...headers,
      }
    }
    return window
      .fetch(`/api/mealPlans/${id}/shoppinglistPDF/`, config)
      .then(response => response.blob())
      .then(blob => {
        const fileURL = URL.createObjectURL(blob)
        window.open(fileURL)
      })
      .catch(error => {
        console.log(error)
      })
  }

  getMealPlan(id, format) {
    const headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'responseType': 'blob',
    }
    const token = this.getToken()
    if (token) {
      headers.Authorization = `Bearer ${token}`
    }
    const config = {
      method: 'GET',
      headers: {
        ...headers,
      }
    }
    return window
      .fetch(`/api/mealPlans/${id}/mealPlan${format}/`, config)
      .then(response => response.blob())
      .then(blob => {
        const fileURL = URL.createObjectURL(blob)
        window.open(fileURL)
      })
      .catch(error => {
        console.log(error)
      })
  }

  getCustomMealPlan(mealplan) {
    const headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'responseType': 'blob',
    }
    const token = this.getToken()
    if (token) {
      headers.Authorization = `Bearer ${token}`
    }
    const config = {
      method: 'POST',
      headers: {
        ...headers,
      },
      body: mealplan
    }
    return window
      .fetch(`/pdf/plan?format=PDF`, config)
      .then(response => response.blob())
      .then(blob => {
        const fileURL = URL.createObjectURL(blob)
        window.open(fileURL)
      })
      .catch(error => {
        console.log(error)
      })
  }

  getRecipe(id, format) {
    const headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'responseType': 'blob',
    }
    const token = this.getToken()
    if (token) {
      headers.Authorization = `Bearer ${token}`
    }
    const config = {
      method: 'GET',
      headers: {
        ...headers,
      }
    }
    return window
      .fetch(`/api/recipes/${id}/recipe${format}/`, config)
      .then(response => response.blob())
      .then(blob => {
        const fileURL = URL.createObjectURL(blob)
        window.open(fileURL)
      })
      .catch(error => {
        console.log(error)
      })
  }

  getCalendar(calendar) {
    const headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'responseType': 'blob',
    }
    const token = this.getToken()
    if (token) {
      headers.Authorization = `Bearer ${token}`
    }
    const config = {
      method: 'POST',
      headers: {
        ...headers,
      },
      body: JSON.stringify(calendar)
    }
    return window
      .fetch(`/pdf/calendar?format=PDF`, config)
      .then(response => response.blob())
      .then(blob => {
        const fileURL = URL.createObjectURL(blob)
        window.open(fileURL)
      })
      .catch(error => {
        console.log(error)
      })
  }

  useCheckPermission({uid, permission, objectType=undefined, objectId=undefined}) {
    const [isLoading, setIsLoading] = useState(true)
    const [error, setError] = useState(null)
    const [hasPermission, setHasPermission] = useState(false)

    useEffect(() => {
      this.request(
        '/api/authorize', {
          body: {uid, permission, object_id: objectId, object_type: objectType}
        }).then((data) => {
        setHasPermission(data == "yes" ? true : false)
        setIsLoading(false)
      }).catch(error => {
        setError(error)
        setIsLoading(false)
        if (error && error.networkError && error.networkError.statusCode == 401) {
          this.logout()
        }
      })
    } , [uid, permission, objectId, objectType])
    return { isLoading, error, hasPermission }
  }

}

export { API, COOKIE_NAME }
