export const COOKIE_NAME = process.env.COOKIE_NAME || 'next_auth_token'
export const API_NAMESPACE = process.env.NL_API_NAMESPACE || '/api'
export const RESET_URL = process.env.NL_RESET_URL || API_NAMESPACE + '/auth/reset-password'
export const CONFIRM_RESET_URL = process.env.NL_CONFIRM_RESET_URL || API_NAMESPACE + '/auth/confirm-password-reset'
export const AFTER_LOGIN_URL = '/dashboard'

export const DAY = 'day'
export const TWO_WEEK = 'two_week'
export const WEEK = 'week'
export const MONTH = 'month'

export const DATE_FORMAT = 'YYYY-MM-DD'

export const CALENDAR_LENGTH = {
  [WEEK]: 7,
  [TWO_WEEK]: 14,
  [MONTH]: 35,
}

export const TYPES = {
  B: 'Breakfast',
  L: 'Lunch',
  D: 'Dinner'
}

export const STRENGTH = {
  0: 'dislike',
  1: 'mild',
  2: 'moderate',
  3: 'severe',
  4: 'dislike'
}

// keys are allergen sensitivity, values are months before expiration
export const EXPIRATION = {
  1: 3,
  2: 7,
  3: 12,
  4: 0
}

export const WEEKDAYS = [
  'Sun',
  'Mon',
  'Tue',
  'Wed',
  'Thu',
  'Fri',
  'Sat',
]

export const RECIPE_TAGS = [
  'breakfast',
  'lunch',
  'dinner',
  'desserts',
  'side',
  'sauce',
  'Herbs/Spices',
]

export const FOOD_TYPES = [
  'American',
  'Chinese',
  'French',
  'Greek',
  'Italian',
  'Japanese',
  'Mexican',
]

export const FOODS_MEAT = [
  'beef', 'buffalo', 'chicken', 'pork', 'turkey', 'venison',
  'anchovy', 'bass', 'clam', 'codfish', 'crab', 'flounder', 'haddock',
  'halibut', 'herring', 'lobster', 'mackerel', 'mussel', 'oyster',
  'perch', 'red snapper', 'salmon', 'scallop', 'shrimp', 'sole',
  'squid', 'swordfish', 'trout', 'tuna', 'walleye pike',
]

export const FOODS_OTHER = [
  'egg', 'dairy', 'nut milk', 'nut flour', 'nuts/seeds', 'fruit', 'grain',
]

export const PORTION_UNITS = [
  {value: 'tsp', label: 'teaspoon'},
  {value: 'tbsp', label: 'tablespoon'},
  {value: 'can', label: 'can'},
  {value: 'cup', label: 'cup'},
  {value: 'pt', label: 'pint'},
  {value: 'qt', label: 'quart'},
  {value: 'l', label: 'liter'},
  {value: 'oz', label: 'ounce'},
  {value: 'gal', label: 'gallon'},
  {value: 'lb', label: 'pound'},
  {value: 'fl oz', label: 'fluid ounce'},
  {value: 'pinch', label: 'pinch'},
  {value: 'dash', label: 'dash'},
  {value: 'clove', label: 'clove'},
  {value: 'stalk', label: 'stalk'},
  {value: 'head', label: 'head'},
  {value: 'bunch', label: 'bunch'},
  {value: 'inch', label: 'inch'},
  {value: 'ml', label: 'milliliter'},
  {value: 'g', label: 'gram'},
  {value: 'mg', label: 'milligram'},
  {value: 'kg', label: 'kilogram'},
  {value: 'floret', label: 'floret'},
  {value: 'spear', label: 'spear'},
  {value: 'slice', label: 'slice'},
  {value: 'ear', label: 'ear'},
  {value: 'leaf', label: 'leaf'},
  {value: 'rib', label: 'rib'},
]
