import React from 'react'
import PropTypes from 'prop-types'

/**
 * Props
 *    className: class to override spinner container
 *    bladeClass: class to override spinner blades
 */

const Spinner = ({className, bladeClass}) => {
  const blades = []

  for (let i = 0; i < 12; i++) {
    blades.push(
      <div
        key={i}
        className={`spinner-blade ${bladeClass}`}>
      </div>
    )
  }

  return (
    <div className={`spinner ${className ? className : 'center'}`}>
      {blades}
    </div>
  )
}

Spinner.defaultProps = {
  className: '',
  bladeClass: ''
}

Spinner.propTypes = {
  className: PropTypes.string,
  bladeClass: PropTypes.string
}


export { Spinner }
